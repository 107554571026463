@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet');

body {
  font-family: Titillium Web, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}

$background_color_1: #ffffff;
$background_color_2: #0070ba;
$background_color_3: #89b0eb;
$background_color_4: rgba(9, 62, 177, 0.68);
$background_color_5: #c32d2d;
$background_color_6: rgba(21, 54, 111, 0.45);

$color_1: #ffffff;
$color_2: #000000;
$color_3: rgba(9, 62, 177, 0.68);
$color_4: #0070ba;

$font_family_1: Titillium Web, sans-serif;

html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}

.TMS_BRN_V3_2_1B {
  header {
    padding: 0 0 75px;

    .logo {
      height: 60px;
    }

    h1 {
      color: #fff;
      font-size: 38px;
      font-weight: 600;
      padding-top: 20px;
    }

    h2 {
      color: #fff;
      font-size: 23px;
      font-weight: 400;
    }

    h3 {
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }

    h6 {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .form-section {
    form {
      .slidetop_adjust {
        position: relative;
        margin-top: -56px;
      }

      h4 {
        margin: 0 !important;
        padding: 0 0 20px;
        font-size: 20px !important;
        color: #777;

        .txt-highlite {
          color: #f7a900;
        }
      }

      h6 {
        font-size: 18px !important;
        color: #777;
      }

      .radio_buttons {
        .radio {
          background: #2f9de8;
          display: inline-block;
          border: 0 solid #0070ba;
          border-bottom: 3px solid #257eba;
          font-size: 20px;
          color: #fff;
          font-weight: 600;
          border-radius: 1px !important;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
          margin: 0 0 20px !important;
          position: relative;
          overflow: hidden;
          text-align: center;
          padding: 16px 15px;
        }

        .radio {
          &:checked + label {
            background: #3ec1ff;
            border: solid #3ec1ff;
            border-width: 0 0 3px;
          }
        }

        input.radio {
          position: absolute;
          visibility: hidden;
        }

        .banknames-radio {
          background: #2f9de8;
          box-sizing: border-box;
          height: 60px;
          position: relative;
          cursor: pointer;
          outline: none;
          padding: 0;
          line-height: 60px;
          border-radius: 4px;
          width: 100%;
          clear: none;
          color: #fff;
          font-weight: 400;
          display: block;
          font-size: 18px;
          text-align: center;
          margin: 0 0 5px;
        }

        .banknames-radio {
          &:checked + label {
            background: #3ec1ff;
          }
        }

        input.banknames-radio {
          position: absolute;
          visibility: hidden;
        }
      }

      .form-select {
        font-size: 16px;
        border: 2px solid #ddd !important;
        height: 50px !important;
        border-radius: 1px;
        color: #777;
      }

      .form-control {
        font-size: 16px;
        border: 2px solid #ddd !important;
        height: 50px !important;
        border-radius: 1px;
        margin-bottom: 10px !important;
      }

      fieldset {
        border: 1px solid #2f9de8;
        padding: 10px;
        border-radius: 0;
        margin-bottom: 15px;

        legend {
          padding: 0 10px;
          font-size: 20px;
          width: auto;
          float: none;
          margin: 0px;
        }
      }

      .manual-entry-btn {
        font-size: 20px;
        color: #636363;
      }

      .not_sure {
        font-size: 18px;
        font-weight: 600;
        color: #777;
        cursor: pointer;
      }

      .back-btn {
        color: #0d6efd;
        cursor: pointer;
        font-size: 20px;
        text-decoration: underline;
      }
    }

    .ssl_secure {
      img {
        height: 30px;
      }
    }
    .privacyico {
      height: 50px !important;
    }
  }

  .content-section {
    background-color: #fff;
    padding: 15px 0px;
    color: #777;

    img {
      margin: 40px 0px;
    }

    h4 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  .accordion-section {
    background-color: #fff;
    padding: 15px 0px;

    h4 {
      font-size: 24px;
    }

    .accordion {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            background-color: #f9f9fa;
            color: #007bff !important;
            font-size: 20px;
          }
        }

        .accordion-body {
          p {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  
  .slideone-modal {
    .modal-content {
      border: solid #2f9de8 !important;
      border-width: 5px 5px 25px !important;
      border-radius: 0 !important;

      .modal-body {
        p {
          font-size: 20px;
          margin: 0 !important;
          padding: 0 0 20px;
          color: #777;
        }

        .modal-bttn {
          background: #2f9de8;
          font-size: 20px;
          color: #fff;
          border: none;
          border-bottom: 3px solid #257eba;
          border-radius: 3px !important;
        }
      }
    }
  }

  .analyz-modal {
    .modal-content {
      border: solid #2f9de8 !important;
      border-width: 5px 5px 25px !important;
      border-radius: 0 !important;

      .modal-body {
        p {
          font-size: 14px;
          margin: 0 !important;
        }
      }
    }
  }

  $borderWidth: 7px;
  $animationTime: 0.5s;
  $border-color-default: #eaeaea;
  $border-color-fill: #2f9de8;
  $size: 120px;
  $howManySteps: 100;

  .progress {
    width: $size;
    height: $size !important;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    background: none !important;
    margin-bottom: 30px;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
    }
    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }
    .perce {
      font-size: 28px !important;
      font-weight: 100;
    }

    .progress-left {
      left: 0;
    }
    .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
      border-color: $border-color-fill;
    }
    .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: ($size/2);
      border-bottom-right-radius: ($size/2);
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
    .progress-right {
      right: 0;
      .progress-bar {
        left: -100%;
        border-top-left-radius: ($size/2);
        border-bottom-left-radius: ($size/2);
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
      }
    }
    .progress-value {
      display: flex;
      border-radius: 50%;
      font-size: 1rem;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-weight: 300;
      z-index: 9;
      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  @for $i from 1 through $howManySteps {
    $stepName: ($i * (100 / $howManySteps));

    @if $i <= ($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }
        .progress-left .progress-bar {
          animation: 0;
        }
      }
    }

    @if $i > ($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
         animation: loading-#{($howManySteps/2)}
            $animationTime
           linear
            forwards; //set the animation to longest animation
        }
        .progress-left .progress-bar {
          animation: loading-#{$i -
            ($howManySteps/2)}
            $animationTime
            linear
            forwards
            $animationTime;
        }
      }
    }
  }

  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/ ($howManySteps/2));
    $degrees: ($degrees * $i);
    @keyframes loading-#{$i} {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }
}

footer {
  padding: 25px 0;
 // background: #333;

  p {
    color: #beb8b8;
    font-size: 14px;
  }

  ul {
    padding: 0;
    margin-top: 15px;

    li {
      list-style: none;
      border-right: 1px solid #beb8b8;
      padding: 0 10px;
      display: inline;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        padding-left: 0px;
      }
    }
  }
}

.questionspart {
  font-family: $font_family_1;
  &::before {
    z-index: -1;
    content: "";
    position: fixed;
    height: 200px;
    width: 100%;
    bottom: 44px;
    left: 0;
    background-position: left bottom !important;
    background-repeat: no-repeat !important;
    background-size: 72%;
    opacity: 0.3;
  }
  &::after {
    z-index: -1;
    content: "";
    position: fixed;
    height: 180px;
    width: 100%;
    top: 2%;
    background-image: url(/assets/img/logo.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 20%;
    opacity: 0.08;
  }

  .btn-primary {
    background: $background_color_2 !important;
    border-color: $color_3 !important;
  }

  .radio-box {
    margin: 45% 0;
    display: inline-block;
    width: 100%;
    h4 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 20px;
      strong {
        font-weight: 700;
      }
    }
    h5 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    label.radioa {
      border: 2px solid $color_3;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      height: 48px;
      position: relative;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 0px 0px 0px 50px;
      line-height: 44px;
      margin-bottom: 10px;
      width: 100%;
      float: left;
      text-align: left;
      clear: none;
      color: $color_3;
      font-weight: normal;
      display: block;
      font-size: 15px;

      span {
        position: absolute;
        left: 2px;
        color: #fff;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        padding: 0px 14px;
        margin: 2px 0px;
        background-color: $background_color_2;
        border-radius: 5px;
      }
    }
    input:checked + label {
      color: #fff;
      background: rgba(9, 131, 168, 0.68) url(/assets/img/checked.png) no-repeat
        6px !important;
      color: #fff !important;
      border: 2px solid $color_3;
    }
  }

  label.radio {
    &:active {
      background-color: $background_color_2;
      border-color: $color_3;
      color: $color_1;
    }
  }

  input.radio {
    position: absolute;
    visibility: hidden;
  }
  .custom-checkbox {
    display: none;
  }

  .cstfrm {
    label {
      background: hsla(0, 0%, 92.5%, 0.54) url(/assets/img/unchecked.png)
        no-repeat 6px;
    }
    label.radioa {
      width: 49%;
      margin: 2px 1px 10px;
      line-height: 44px;
      padding: 0px 0px 0px 34px;
    }
  }
  .custom-checkbox:checked + label {
    background: rgba(9, 131, 168, 0.68) url(/assets/img/checked.png) no-repeat
      6px !important;
    color: #fff;
    border: 2px solid $color_3;
  }

  .btn-div {
    margin: 50% 0 300px;
    .btn-submit {
      background: $background_color_2;
      padding: 10px;
      color: $color_1;
      font-size: 17px;
      font-weight: 500;
      width: 100%;
      border: 0px;
      border-radius: 5px;
    }
  }
}

.statusbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $background_color_4;
  width: 100%;
  border-top: solid 1px $color_3;
  padding: 5px 0;
  .arrow-up {
    background: $background_color_2 url(/assets/img/arrow-up.png) no-repeat
      center center;
    width: 20px;
    height: 12px;
    padding: 13px !important;
    float: right;
    margin: 2px;
    border-radius: 5px;
    cursor: pointer;
  }
  .arrow-down {
    background: $background_color_2 url(/assets/img/arrow-down.png) no-repeat
      center center;
    width: 20px;
    height: 12px;
    padding: 13px !important;
    float: right;
    margin: 2px;
    border-radius: 5px;
    cursor: pointer;
  }
  #myProgress {
    width: 100%;
    background-color: $background_color_3 !important;
    margin-bottom: 0px;
    border-radius: 15px !important;
    overflow: hidden;
    height: 15px;
    margin: 9px 0;
    display: none;
  }
  .myBar {
    margin: 0px 0 0;
    padding: 0px 0 0;
    font-weight: 500;
    height: 15px;
    background-color: $background_color_2;
    text-align: center;
    color: $color_1;
    border-radius: 15px !important;
    transition: 0.4s linear;
    transition-property: width, background-color;
    font-size: 11px;
  }
  #myBar {
    transition: 0.4s linear;
    transition-property: width, background-color;
  }
  .progress-value {
    width: 100%;
    background-color: $background_color_3 !important;
    margin-bottom: 0px;
    border-radius: 15px !important;
    overflow: hidden;
    height: 15px;
    margin: 9px 0;
    color: $color_1;
    font-size: 11px;
    padding-left: 10px;
  }
}
.error_msg {
  color: #fff;
  width: 100%;
  float: left;
  background: red;
  font-size: 13px;
  padding: 0 5px;
  border-radius: 4px !important;
  -webkit-border-radius: 4px;
  margin-bottom: 10px;
}
.error {
  display: none;
  color: #fff;
  background: #f00;
  width: 100%;
  padding: 2px 5px;
  float: left;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
}

.sign_msg{
  color: #fff;
  background: #f00;
  width: 100%;
  padding: 2px 5px;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
  margin-top: 10px;
}

.sign {
  background: #257eba;
}
.logo-part {
  img {
    margin: 0;
    height: 60px;
  }
}
.gif1 {
  font-family: $font_family_1;
  img {
    margin: 50px 0 10px;
    height: 100px;
  }
  h1 {
    padding: 10px 0;
    color: #fb1414;
  }
  .unqualifiedty .gradient_sec .gif1 h6 {
    font-weight: 600;
    font-size: 18px;
  }
}

// .modal {
//   input{
//     display: none;
//   }
//   label.radioa {
//     background: rgba(236,236,236,0.54) url(/assets/img/unchecked.png) no-repeat 6px;
//     border: 2px solid $color_3;
//     -webkit-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     box-sizing: border-box;
//     border-radius: 4px;
//     -webkit-border-radius: 4px;
//     -moz-border-radius: 4px;
//     height: 48px;
//     position: relative;
//     cursor: pointer;
//     outline: none;
//     margin: 0;
//     padding: 0px 0px 0px 50px;
//     line-height: 44px;
//     margin-bottom: 10px;
//     width: 100%;
//     float: left;
//     text-align: left;
//     clear: none;
//     color: $color_3;
//     font-weight: normal;
//     display: block;
//     font-size: 15px;

//     span {
//       position: absolute;
//       left: 2px;
//       color: #fff;
//       font-weight: 400;
//       height: 40px;
//       line-height: 40px;
//       padding: 0px 14px;
//       margin: 2px 0px;
//       background-color: $background_color_2;
//       border-radius: 5px;
//     }
//   }
  
//   input:checked+label {
//     color: #fff;
//     background: rgba(9,131,168,0.68) url(/assets/img/checked.png) no-repeat 6px !important;
//     color: #fff !important;
//     border: 2px solid rgba(9,62,177,0.68);
// }
//   input.radio {
//     position: absolute;
//     visibility: hidden;
//   }

//   .radio {
//     &:checked + label {
//       background-color: #0983a8ad !important;
//       color: #fff;
//     }
//   }
//  input.regNextBtnNew{
//   padding: 10px 0;
//   margin: 0.5rem;
//   color: #ffffff;
//   border-radius: 5px !important;
//   display: inline-block;
//   background: #2f9de8;
//   border-bottom: 3px solid #257eba;
//   border: 0px solid #0070ba;
//   font-weight: 600;
//   align-items: center;
//   transition: all .3s;
//   text-decoration: none;
//   margin-bottom: 20px !important;
//   width: 97%;
//   flex: 0 0 109px;
//   box-sizing: border-box;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
//  } 


// }

.prevname-outer input[type="text"]{
  display: block !important;
}
.prevname-Btnpop input[type="button"]{
  display: block !important;
}

.signature-section {
  font-family: $font_family_1;
  padding: 60px 0 !important;
  height: auto;
  .sign-question {
    padding: 0px;
    h2 {
      text-align: center;
      font-size: 20px;
      color: #fff;
      padding: 20px 15px;
      font-weight: 600;
      background: #257eba;
      margin-bottom: 10px;
    }
    ul {
      margin: 0;
      padding: 0 40px;
      li {
        background: url(/assets/img/download.png) no-repeat 0;
        list-style: none;
        padding: 5px 0 5px 35px;
        margin-bottom: 0;
        font-size: 17px;
        text-align: left !important;
      }
    }
  }
  .sign-div {
    .signature_canvaseWrap {
      .signature-pad {
        display: block;
        margin: 0 auto;
        // width: 100%;
        background: url(/assets/img/sign-bg.png) no-repeat #fff 49% 67px !important;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
    .form-check-input {
      background-color: #fff;
      border-color: #999999;
    }
    #acceptterms{
      width: 30px;
      height: 30px;
    }
    #acceptterms_flp{
      width: 15px;
      height: 15px;
    }
    .input_1{
      width: 1em !important;
      height: 1em !important;
      margin-left: -1.5em !important;
      position: absolute;
    }
    .sign-custom-control-label{
      font-size: 16px !important;
      width: 98%;
      padding-left: 10px;
    }
    .form-check-input:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
    label {
      color: #212529 !important;
      font-size: 14px;
    }
    .btn-clear {
      background-color: #fb0404;
      font-size: 15px;
      color: #fff;
      font-weight: 400;
      font-size: 20px !important;
      border-radius: 4px;
      padding: 9px 15px;
      border: 0;
      width: auto;
      cursor: pointer;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .btn-submit {
      background-color: #309814;
      font-size: 15px;
      color: #fff;
      font-weight: 400;
      font-size: 20px !important;
      border-radius: 4px;
      padding: 9px 50px;
      border: 0;
      width: auto;
      cursor: pointer;
      margin-bottom: 10px;
      text-transform: uppercase;
      float: right;
    }
    .anim_ylw {
      -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
      -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
      -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
      animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
      box-shadow: 0 0 0 0 #f1ee05;

      @-webkit-keyframes pulse {
        to {
          box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
        }
      }

      @-moz-keyframes pulse {
        to {
          box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
        }
      }

      @-ms-keyframes pulse {
        to {
          box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
        }
      }

      @keyframes pulse {
        to {
          box-shadow: 0 0 0 15px rgba(232, 76, 61, 0);
        }
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    -webkit-transition: -webkit-transform 2.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
.hide{
  display: none !important;
}
.show{
  display: block !important;
}

@media (max-width: 767px) {
  .TMS_BRN_V3_2_1B {
    header {
      .logo {
        height: 50px;
      }

      h1 {
        font-size: 28px;
        padding-top: 15px;
      }

      h2 {
        font-size: 21px;
      }
    }

    .content-section {
      .bordr {
        border-bottom: 2px dashed #257eba;
        border-top: 2px dashed #257eba;
      }
    }

    .accordion-section {
      background-color: #69a2d7;
      padding: 40px 0 30px;
      color: #fff;

      .accordion {
        .accordion-item {
          .accordion-body {
            p {
              color: #000;
            }
          }
        }
      }
    }

    footer {
      p {
        color: #fff;
        font-size: 13px;
        text-align: center;
      }

      ul {
        li {
          display: block;
          padding: 10px 0px;
          border-right: none;
          font-size: 13px;
          border-top: 1px dotted #94a2a6;

          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }

  .questionspart {
    &::after {
      background-size: 45%;
    }
  }
  .modal {
    padding-top: 15%;
  }
}

@media (min-width: 576px) {
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.7 !important;
}