.questionnaire26{
/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
 

/*================================================
03 - BODY STYLES
==================================================*/
$color1: #3A8DDB;
$color2: #1891a5;
$color3: #1B1B1B;
$color4: #1FCCF2;
$color5: #DF4514;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #EF8019;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;


$font_family_1: 'Poppins', sans-serif !important;  

 

$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;


input[type="radio"] {
  display: none;
}

.que-title{
  color: #000;
}

.active{
  .que-title{
    color: #9b9b9b !important;
  }
}


 *{
  outline: none;
 }
.banner {
  &.qs-section {
    height: initial;
  }
}


.questionnair-section {
  padding-left: 10rem;
  position: relative;
  ul {
    li{
      // &:nth-child(1) {
      //   .questitem {
      //     h1 {
      //       color: black;
      //     }
      //   }
      // }
    }
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    background: #ffffff24;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(77px);
  }
  .questitem {
    background: $white-color;
    border-radius: 10px;
     position: relative;
     margin-bottom: 1rem;
    cursor: pointer;


     &::before {
      content: "";
      width: 25px;
      height: 25px;
      background: $white-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translateX(-95px);
      transition:all ease-in 0.5s ;
      animation:pulse1 1500ms infinite;

      @keyframes pulse1{
        0%{
          box-shadow: #59d4fa 0 0 0 0;
        }
        75%{
          box-shadow:#ff69b400 0 0 0 16px;
        }
      }
     
      
    }
    &.active {
     &::before {
      background: $color7 url("../../img/check.png") no-repeat ;
      transition:all ease-in 0.5s ;
      background-position: center center;
      animation: initial;
     }   
    }

    h1 {
      font-size: 1.1rem;
      margin: 0;
      //color: #9b9b9b;
      
      &.text-style {
        color: $black-color !important;
        // text-decoration: line-through;
      }
      &.text-finish {
 
        color: #9b9b9b !important;
      }
    }
  }
  fieldset {
    border: 1px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }

legend {
    width: auto;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 400;
    float: none;
    text-align: left;
  } 

input,
select {
    border-color: #d1d1d1;
    font-size: .9rem;
    height: 100%;
    min-height: 45px;
  }
  
  
}

.optionbtn {
  position: relative;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
  }
  input:checked + label {
    background: $color4;
    color: $white-color;
  }
  label {
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid $color4;
  }
  .label2 {
    padding: 15px 12px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .un-hide-input {
    position: relative;
    opacity: 1 !important;
    width: auto !important;
    height: 40px !important;
    visibility: visible !important;
  }
  
  .option-grid-iande {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    
    li {
      width: 100%;
      
      .optionbtn {
        label {
          width: 100%;
          text-align: center;
        }
        
      }
    }
  }
}


.income-exp {
  font-size: 18px;
  label {
    width: 100%;
    text-align: center;
  }
}

.option-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  &.option-gridV2 {
    justify-content: center;
    
    li {
      width: 32%;
    }
  }
  
  li {
    width: 24%;
    .optionbtn {
      label {
        width: 100%;
        text-align: center;
      }
    }
    .disabledcheckbox {
      // background: #fff url(../img/unchecked.png) no-repeat 7px center !important;
			color: #aeb2d9 !important;
			border: 2px solid #cbd5e4 !important;
		}
  }
  .adjustbalewid{
    width:19%;
  }
  .adjustbalewidfull{
    width:100%;
    .optionbtn {
      label {
        padding: 7px 20px;
        text-align: left;
      }
    }
  }
  .adjustbalewidhalf{
    width:49% !important;
  }
}

.popupclaim {
  h4 {
    font-size: 1.2rem;
  }
}
.loader-wrap {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Qv15-description{
  color: red;
  line-height: 16px;
  small{
    @media (max-width: 510px) {
      font-size: 11px !important;
      line-height: 10px;
    }
  }
}

.activeclass{
  background: $color4 !important;
  color: #fff !important;
}
.h-55 {
  height: 55px;
}

.shadow-style {
  transition: all ease-in 0.5s;
  animation: pulse2 1500ms infinite;
  
  @keyframes pulse2 {
    0% {
      box-shadow: #ef8019 0 0 0 0;
      }
      
      75% {
        box-shadow: #ff69b400 0 0 0 16px;
      }
    }
  }
  
  $diagonal: 26px; // 13px arrow
  $size: $diagonal / 1.414; // Формула диагонали квадрата, d = sqrt(2)*a;
  $half: ($diagonal / 2);
  $halfNegative: ($diagonal / -2);
  
  .tipbox {
    width: 100%;
  height: auto;
  position: relative;
  background:rgba(24, 206, 15, 0.8);
  margin-top: 10px;
  color:#fff;
  padding:10px;
  border-radius:7px;
  font-size: 15px;
  text-align: center;
  animation: pulse1 1500ms infinite;
  margin-bottom: 15px;
}

.arrow {
  position: absolute;
  overflow: hidden; 
  &:after {
    content: '';
    background: rgba(24, 206, 15, 0.8);
    width: $size;
    height: $size;
    position: absolute;
    border: 2px solid #0dd624;
    transform-origin: 0 0;
  }
}

.arrow-top {
  width: $diagonal;
  height: $half;
  left: 10%;
  top: $halfNegative;
  margin-left: $halfNegative;
  
  &:after {
    left: $half;
    top: 0;
    transform: rotate(45deg);
  }
}

.qrange{
  position: fixed;
  z-index: 9;
  top:20px;
  right: 20px;
  width: 100px;
  height:100px;
  text-align:center;
  color:#fff;
  border-radius: 50%;
  vertical-align: middle;
  background-color:rgba(2,55,90,0.8);
  cursor: pointer;
  justify-content: center;
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  transition: all 0.35s cubic-bezier(.91,-0.28,.06,1.3),background-color 0.25s ease;
  
  &:hover{
    background-color:#004c70;
  }
  .giveSpace{
    @media (max-width: 579px){
      display: none;
    }
  }
  @media screen and (max-width: 579px){
    bottom: 0;
    width: 100%;
    right: 0;
    top: inherit;
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    
  }
  
  
}

@media screen and (max-width: 576px){
  align-items-sm-center {
    align-items: center !important;
  }
  
}

.backbtn1a{
  margin-top: 12px;
  height: 30px;
  font-size: 14px;
  padding: 2px 14px 3px 14px;
}
.btn-green-skip{
  background-color: rgba(24, 206, 15, 0.8) !important;
  color: #ffffff !important;
  width: 100% !important;
  height: 100% !important;
}

.cursor-placeholder
{
  &:focus::placeholder {
    color: transparent;
  }
}
.bck-marwid{
  margin-bottom: 4px;
}
.autocomplete-wrapper {
  position: relative;

  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 170px;
    overflow-y: scroll;
    
    li {
      padding: 8px;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }
  .borderboxxx{
    border: 1px solid #1fccf2 !important;
  
  }
}

@media screen and (max-width: 1080px){
  .autocomplete-wrapper {  
    .suggestions-list {
      max-height:315px;
    }
  }
}



//////////////////////////////////////////////////////////////////////


.height-scale {
  -webkit-animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-down-ver-center {
	-webkit-animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation-delay: .3s;
}

////////////////////////// Keyframe ////////////////////////////////////////////

@-webkit-keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
   
            -webkit-transform: scaleY(1);
            transform: scaleY(1);

  }
}
@keyframes scale-down-ver-center {
  0% {
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}



////////////////////////// Keyframe ////////////////////////////////////////////


@media screen and (min-width: 960px) {
   .form-style {
    select {
      height: 100%;
    }
   }
}

@media screen and (max-width: 470px) {
  .questionnair-section {
    padding-left: 2rem;
    &::before {
      transform: translateX(8px);
    }
    .questitem{
      h1 {
        font-size: 1rem;
      }
      &::before  {
        transform: translateX(-29px);
        width: 18px;
        height: 18px;
        
      }
      &.active {
        &::before {
          background-size: 12px;
        }
      }
    }
    legend {
      font-size: 13px !important;
  }
  }
  .option-grid {
    justify-content: center;
    &.option-gridV2 {
      justify-content: center;
      
      li {
        width: 100%;
      }
    }
    li {
      width: 47%;
      .optionbtn {
        label {
          padding: 15px 5px;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
    .adjustbalewid{
      width:48% !important;
    }
    .adjustbalewidfull{
      width:100% !important;
    }
    .adjustbalewidhalf{
      width:48% !important;
    }
  } 
  .input-mobile {
    width: 100%;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-radius: 0;
    font-size: 12px !important;
}

.input-group>.form-control {
    border-radius: 0 !important;
    margin-left: 0px;
}

.btn-comm2 {
    padding: 20px 10px;
}
}
}
.choose_Y_N{
  display: flex;
  width: 100%;
  gap: 8px;
  margin-bottom: 30px;
  @media (max-width: 510px){
      flex-direction: column;
      gap: 15px;
  }
/////////////////////////// form check /////////////////////////////////
.form-check {
  // width: 50%;
  @media (max-width: 510px){
      width: 100% !important;
  }
  [type="radio"] {
      display: none;
  }
  // & + .form-check {
  //  width: 50%;
  // }
  label {
      display: block;
      padding: 13px 60px;
      background: #fff;
      border: 1px solid #a3a3a3;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      min-width: 250px;
      color: #002448;
      white-space: nowrap;
      position: relative;
      transition: 0.4s ease-in-out 0s;
      &:after{
          height: 19px;
          width: 19px;
          border: 1px solid #18478b;
          left: 19px;
          top: calc(50% - 10px);
      }
      &:before {
          background: #004d8b;
          height: 11px;
          width: 11px;
          left: 23px;
          top: calc(50% - 6px);
          transform: scale(5);
          opacity: 0;
          visibility: hidden;
          transition: 0.4s ease-in-out 0s;
      }
  }
  [type="radio"]:checked {
      & ~ label {
          border-color: #004d8b;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          background: #4285f4;
          color: #ffffff;
          &::before {
              background: #fff;
              opacity: 1;
              visibility: visible;
              transform: scale(1);
          }
          &::after{
              border: 2px solid #fff;
          }
      }
  }
}
.form-check label:after,
.form-check label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}
///////////////////////// form check /////////////////////////////////
}//choose_bw
.btn-comm2modalreject {
	background:  #DF2C2C;
  padding: 20px 40px;
	border: none;
  position: relative;
	z-index: 1;
  color: #fff;
  border-radius:5px;
  outline-color: none;
  span {
    position: relative;
    z-index: 4;
  }

	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 100%;
		top: 0;
		right: 0;
		background-color:  #ff912b;
		transition: all 0.3s ease;
    z-index: -1;
    border-radius:5px;
	}
	&:hover {
		color: #ffff;
		&:after {
			left: 0;
			width: 100%;

		}
	}
	&:active {
		top: 2px;
	}
}
