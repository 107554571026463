.thankyouNDY {
    min-height: 100vh;
    header {
        background-color: #257eba;
        padding: 20px 0px;

        img {
            height: 60px;
        }
    }

    section {
        padding: 100px 0px;


        h5 {
            font-size: 40px;
            font-weight: 700;
        }
    }
    .inner-height{
        padding: 50px 0px;
    }

    .footerclass {
        // bottom: 0px;
        position: absolute;
        width: 100%;
        background-color: #212529;
    }

    footer{
        ul{
            li{
                a{
                color: #beb8b8 !important;
                font-size: 13px;
                }
            }
        }
    }

    .fixd-ftr {
        position: fixed;
        width: 100%;
        bottom: 0px;
    }

    @media (max-width: 767px) {
        .footerclass {
            position: relative !important;
        }
    }
    .thankyouPopup{
        .modal-backdrop {
            background: rgba(0, 0, 0, 0.85) !important;
            opacity: 1;
        }
    
        .modal-content {
          background: #3a8ddb;
          background-size: cover;
          color: white; /* Optional: Change text color for better visibility */
          .modal-body{
              padding: 30px;
          }
          .modal-footer{
              border-top: 1px solid #ffffff30 !important;
              .btn{
                  background-color: #ef453d;
                  position: relative;
                  margin-left: auto;
                  margin-right: auto;
                  color: #ffffff;
              }
          }
      
        }
      }

}