.VAQ_ID_5 {
    @import 'variables';

    @mixin content {
        content: '';
        position: absolute;
        width: 20px;
        height: 40px;
        background: #0077b6;
        border-radius: 3px;
        animation: upload 1s ease-in-out infinite alternate;
    }


    body,
    html {
        margin: 0;
        font-family: 'Arial', sans-serif !important;
        background: url("../img/main-banner.png") no-repeat center center fixed;
        background-size: cover;
        color: #fff;
    }

    .container {
        text-align: center;
        padding: 20px;
    }

    .logo img {
        max-width: 200px;
        margin: 20px auto;
        display: block;
    }

    .identity-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        margin-bottom: 70px;
        margin-top: 50px;

        .upload-animation {
            width: 80px;
            height: 100px;
            position: relative;

            &::before {
                @include content;
                left: 20px;
                animation-delay: 0s;
            }

            &::after {
                @include content;
                left: 40px;
                animation-delay: 0.2s;
            }
        }
    }


    @keyframes upload {
        0% {
            height: 40px;
        }

        100% {
            height: 80px;
        }
    }

    .verify-heading {
        font-size: 1.75rem;
        font-weight: bold;
        color: white;
        margin-bottom: 10px;
    }

    .instruction {
        font-size: 1.125rem;
        margin-bottom: 10px;
        color: white;
    }

    .dropdown-buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .dropdown-item {
        background: #ffffff;
        color: #0077b6;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        max-width: 600px;
        /* Adjust to col-6 equivalent */
        position: relative;

        input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        .upload-icon {
            margin-left: 10px;
            width: 24px;
            height: 24px;
        }

        &:hover {
            background: #e0f7fa;
            color: #0077b6;
        }
    }




    .footer-note {
        font-size: 0.9rem;
        margin-top: 20px;
        color: white;
    }

    .onboarding-message {
        font-size: 21px;
    }

    .uploadicons {

        background-color: #fff;
        padding: 21px;
        border-radius: 50%;
        margin-top: 12px;

    }

    .fade {
        background: #000000db;
    }

    #quetWraper {
        color: #1c1c1c !important;
    }

    .intro {
        h2 {
            font-weight: 600;
            font-size: 26px;
            margin: 0 0 15px 0;
            text-align: center;
        }

        h3 {
            font-weight: 600;
            font-size: 20px;
            padding-bottom: 15px;
            text-align: center;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            font-style: italic;
            text-align: center;
        }
    }

    .accordion-item {
        border: 0 !important;
    }

    .accordion-button {
        background-color: #fff !important;
        margin-bottom: 15px;
        box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 12px !important;
        color: #3a8ddb !important;
        font-weight: 700 !important;
        border-radius: 10px !important;
    }

    .accordion-body {
        box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 12px !important;
        border-radius: 10px !important;
        margin-bottom: 20px;
    }

    .accordion-button:after {
        border: 5px solid #4382bd;
        padding: 15px;
        background-position: 50%;
        border-radius: 100%;
        background-color: #fff;
    }

    .uploading-part {

        .upload-area {
            /* margin: 20px 0; */
            border-radius: 20px;
            padding: 20px 0;
            text-align: center;
            background: #fff;


        }
    }

    .button-tab {
        background: #ef453d;
        color: #fff !important;
        padding: 15px 0;
        border: 0;
        width: 46%;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: 500;
        display: inline-block;
        text-decoration: none;
        margin: 25px 1% 1%;
        position: relative;
        overflow: hidden;
        font-size: 14px;
        cursor: pointer;

        input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            display: block;
            cursor: pointer;
        }

        img {
            padding-right: 10px;
        }

    }




    .browsebut {
        background: #ef453d !important;
        cursor: pointer;
    }

    .doc-op ul {
        text-align: center;
        padding: 0;
    }

    .doc-op ul li {
        display: inline;
        font-size: 13px;
        font-style: italic;
        font-weight: 600;
        padding: 0 10px;
        border-right: 3px solid #1c1c1c;
    }

    .doc-op ul li:last-child {
        border: 0;
    }


    .questionistmod li {
        font-size: 14px !important;
        padding-right: 10px;
        font-weight: 500;
        margin-top: 18px;
        list-style-type: revert-layer I !important;
    }

    .que-title {
        font-size: 1.1rem;
        margin: 0;
        color: #9b9b9b;
        line-height: 30px;
    }

    @media screen and (max-width: 579px) {
        .que-title {
            font-size: 1rem;
            line-height: 24px;
        }

        .option-grid {
            justify-content: center;

            li {
                width: 47%;

                .optionbtn {
                    label {
                        padding: 15px 5px;
                        font-weight: bold;
                        font-size: 13px;
                    }
                }
            }

            .adjustbalewid {
                width: 48% !important;
            }

            .adjustbalewidfull {
                width: 100% !important;
            }

            .adjustbalewidhalf {
                width: 100% !important;
                padding-right: 0 !important;
            }
        }

        .dropdown-item {
            font-size: 14px;
        }

    
    }


    .text-style.que-title {
        color: #000 !important;
    }

    .questitem {
        background: #fff;
        border-radius: 10px;
        position: relative;
        margin-bottom: 1rem;
        cursor: pointer;
        box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 12px !important;
    }

    .question-content {
        color: #000 !important;
    }


    @media screen and (max-width: 767px) {
        .button-tab {
            width: 100%;
            margin: 10px 1%;
            font-size: 15px;
        }
    }

    .optionbtn {
        position: relative;

        input {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 100%;
            visibility: hidden;
        }

        span {
            width: 20px;
            height: 20px;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 2px solid #1fccf2;
            left: 7px;
        }

        input:checked+label span {
            background: #306fce url(../img/tick-1.png) center no-repeat;
            background-size: 20px;
            border: 2px solid #306fce;
        }

        input:checked+label {
            background: $color4;
            color: $white-color;
        }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 20px;
            position: relative;
            background: #fff;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            border: 1px solid $color4;
        }

        .activeclass {
            background: $color4;
            color: $white-color;
        }
    }

    .option-grid {
        display: flex;
        gap: 10px;
        width: 100%;
        flex-wrap: wrap;

        li {
            width: 24%;

            .optionbtn {
                label {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .adjustbalewid {
            width: 19%;
        }

        .adjustbalewidfull {
            width: 100%;

            .optionbtn {
                label {
                    padding: 7px 40px;
                    text-align: left;
                }
            }
        }

        .adjustbalewidhalf {
            width: 49% !important;
        }
    }

    .id-success {
        background: #2aa406 !important;
        padding: 15px 0;
        border: none;
    }
}