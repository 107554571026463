.UFC_DC_V1{
/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$color01: #3A8DDB;
$white: #fff;
$color13: #9b9b9b;
$color5: #BD3333;

/*================================================
03 - BODY STYLES
==================================================*/
* {
  outline: none;
}

.fade{
  background: #000000d6;
}
 
.inner-section {
  background: white;
  border-radius: 20px   ;
  padding: 40px;
  margin-bottom: 6rem;
  .hd-lop {
        h1 {
          font-size: 24px;
        }
  }
  .inner {
    background: #E3F7FF;
    padding:40px;
    border: 2px solid #3392B8;
    max-width: 800px;
    margin: 0 auto;
    .qs-title {
      font-size: 24px;
      line-height: 36px;
      color:  #246782;
    }
  }
}

.btn-option {
 padding: 2rem 0;
 margin: 0;
  li {
    width: 32%;

  }

  &.optinal-items {
    gap: 15px 10px;

    &.two-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap:30px;

      li {
        width: 100%;
      }


    }

    &.one-options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      li {
        width: 100%;
      }
    }

    .option_but {
      label {
        font-size: 15px;
        padding: 15px 10px 15px 10px;
        font-weight: 600;
        font-size: 18px;
        background: $white;
         box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 1px;
        .tickicon {
          position: relative;
          top: 3px;
        }
      }
    }



  }


  .option_but {
    
    position: relative;

    input {
      position: absolute;
      display: none;
    }


    label {
      padding: 10px;
      display: block;
      border: 1px solid $color01;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      
       
      .tickicon {
        width: 25px;
        height: 25px;
        background: $color01;
        border: 2px solid  $white ;
        border-radius: 50%;
        display: inline-block;

      }
    }

    input:checked+label {
      background:  #00BD8E;
      color: $white;
      
       .tickicon {
        background:  url(../../img/tick03.png) no-repeat center center;
        background-size: 14px;
        border: none;
        border: 2px solid  $white ;
        
      }  
    }

  }

}

.qsSlider {
  .swiper-slide {
    height: auto !important;
  }
 
}

.back-btn {
 font-size: 20px;
 border: 0;
 background: transparent;

}

.below-text {
  font-size: 14px;
}
 
.sginature {
  .text2-info {
    background:  #CAFFF2;
    color: #36574e;
   }
   .ownedupto {
    font-size: 24px;
    span {
      background: #FFCE2D;
    }
   }
   .text-greatnews{
      font-size: 30px;
      span {
        font-weight: bold;
        font-size: 50px;
      }

      

   }
   .checkbox-set {
    font-size: 18px;
   }
  
}


.signboxsection {
  p {
    font-size: 15px;
  }

  .signbox {
    canvas {
      background: #e4e4e4  url(../../img/signhere.png) no-repeat center center  ;
      height: 350px;
      border: 2px dotted $color13;
      border-radius: 10px;
    }

    .sign-clear {
      background: $color5;
      bottom: 20px;
      right: 12px;
      font-size: 14px;

    }
  }
}
 
.checkbox-set {
  font-size: 13px;
 align-items: baseline;
  
  .check-input {
    height: inherit;
    position: relative;
    height: 15px;
    width: 15px;
  }
  label {
    cursor: pointer;
  }
}


.sign-blowtext {
  .sig-title {
    font-weight: 600;
    color: $color01;
    font-size: 15px;
  }
  .link-list {
    display: flex;
    flex-wrap: wrap;
    gap:15px;
     li {
      display: flex;
      gap: 4px;
      color: $color01;
      text-decoration: underline;
     // margin-bottom: 5px;
      //width: 50%;
     }
  }
}


@media screen and (max-width: 460px) {
  .stickybtn {
    position: sticky;
    bottom: 0;
  }
  .inner-section {
    padding: 20px;
    .inner {
      padding: 20px;

      .qs-title{
        font-size: 17px;
    line-height: 27px;
      }
    }
    .hd-lop {
       img {
        height: 50px;
       }
       h1 {
        font-size: 16px;
       }
    }
  }
  .sub-text {
    font-size: 13px;
  }

  .below-text {
    font-size: 11px;
    img {
      height: 24px;
    }
  }

  .btn-option{
    &.optinal-items{
      &.two-options {
        gap: 15px;
      }
      .option_but {
        label {
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }


  .back-btn {
    font-size: 16px;
  }  
  .sign-blowtext {
    .sig-title {
      font-size: 13px;
    }
     .link-list {
      li  {
        font-size: 12px;
      }
     }
  }

  
  .checkbox-set {
    align-items: start;
    .check-input {
      height: 26px;
      width: 26px;
    }
  }
  .sginature {
    .text-cp {
      p {
        font-size: 14px;
      }
    }
    .checkbox-set {
      font-size: 14px;
    }
     .ownedupto {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
    .text-greatnews {
      font-size: 20px;
      span {font-size: 28px;}
    }
  }

  .signboxsection {
    p {
      font-size: 15px;
    }
  
    .signbox {
      canvas {
        height: 300px;
        border: 0;
        border-radius: 0px;
      }
  
      
    }
  }


}
@media (min-width: 576px) and (max-width: 992px){
  .over_craft {
      max-width: none !important;
      margin-right: auto;
      margin-left: auto;
  }
}

}

