.loa-sec {
  


  .sign_preview {
    background: #eee;
    padding: 10px;
    outline: none;
    border: none;
}



.show-1{ 
  display: block;
  animation: 1s ease-out 0s 1  slideInFromBottom;
  }
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .hide-1{ 

    animation: 1s ease-out 0s 1  slidetoBottom;
    display: none;
    }
    @keyframes slidetoBottom {
      
      0% {
        transform: translateY(0);
        display: block;
      }

      100% {
        transform: translateY(100%);
        display: none;
      }
  
    }

.futp{
  color: #fff !important;
  padding: 15px 0  !important;
  font-size: 17px;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
}
.wid100{
  width: 100%;
  max-width: 1170px;
  margin:0 auto;
  @media only screen and (max-width: 768px) {
    max-width: inherit;
    width: 100% !important;
  }

}
  .anim_ylw_preview {
    transition:all ease-in 0.5s ;
    animation:pulse2 1500ms infinite;
  
    @keyframes pulse2{
      0%{
        box-shadow: #ef8019 0 0 0 0;
      }
      75%{
        box-shadow:#ff69b400 0 0 0 16px;
      }
    }
  }
  .preview_1212a{ 
    background: #eee;
    font-family: 'Roboto', sans-serif;
    color: #333333;
  
    .container-1{ 
      margin: 120px auto;
      font-family: 'Open Sans', sans-serif;
      background-color: white;
      min-height: 1080px;
      max-width: 794px!important;
      table{ 
        font-family: 'Open Sans', sans-serif;
      }
      p{
        font-size: 13px;
        margin-bottom: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
      }
  }
  
  h5{ 
    font-size: 13px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .box_1a{ 
    border: 1px solid black; 
    padding: 90px 90px ; 
    margin-bottom: 18px;
  }
  
 
  
  li{
      font-size: 13px;
      padding-right: 10px;
      font-weight: 600;
      margin-top: 16px;
  }
  
   table{ 
    width: 100%;
  } 
  
  .box_2a{ 
    border: 2px solid #416f9c;
    border-radius: 40px;
    height: 200px;
    padding:  16px 12px ;
    width: 300px;
    text-align: center;
  }
  footer{ 
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  .p_adjst{ 
    font-size: 12px;
  }
  
  .sign-div{ 
    width: 98%; 
    margin: auto;
    border: 1px solid black; 
    height: 60px;
  }
  
  
  
  
  
  
  
  @media only screen and (max-width: 600px) {
   
    .container-1{ 
      padding: 1%;
      width: 98%;
      margin: auto;
      font-family: 'Open Sans', sans-serif;
      background-color: white;
      min-height: 500px;
  }
  p{ 
    font-size: 11px;
  }
  li{ 
    font-size: 11px;
  }
  h5{ 
    font-size: 12px;
  }
  .box_1a{ 
    padding: 10px;
  }
  
  .p_adjst{ 
    font-size: 10px;
  }
  }
  
  
   }






















  //margin-top: 0.6cm;
  //margin-left: 1cm;
  //margin-right: 1cm;
  margin-bottom: 0.6cm;
  font-size: 14px;
  color: #000;
  background-color: #eee;
  padding-top: 20px;

  @page {
    margin: 0cm;
    padding: 0cm;
  }

  /** Define now the real margins of every page in the PDF **/

  /** Define the footer rules **/
  /* footer {
         position: fixed;
         bottom: 1cm;
         left: 2cm;
         right: 2cm;
         height: 2cm;
         }
         header {
         position: fixed;
         left:2cm; 
         right:2cm;
         height: 2cm;
         top:1cm;
         } */
  table {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  table tbody {
    width: 100%;
  }

  .page-break {
    page-break-after: always;
  }
  .ft_col_last{
    padding: 70px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    padding: 0px;
    color: #000;
    line-height: 22px;
    margin-bottom: 10px;
  }

  ul,
  ol {
    margin: 0px;
    font-size: 13px;
    padding-left: 20px;
  }

  li {
    font-size: 14px;
    padding-left: 10px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 20px;
    color: #312783;
    margin: 0px;
    padding: 0;
  }

  h2 {
    color: #4e7f36;
    margin: 0px;
  }

  td {
    vertical-align: bottom;
  }
  .baselinecls{
    vertical-align: baseline !important;
  }

  h1 {
    padding: 0;
  }

  .f-wb {
    font-weight: bold;
  }

  /* colors */
  .color_one {
    color: #271c6f;
  }

  .color_two {
    color: #68a56f;
  }

  .color_three {
    color: #585858;
  }

  .color_black {
    color: #000;
  }

  /* colors */
  .text_one {
    font-size: 26px;
    font-weight: bold;
    line-height: 20px;
  }

  .text_two {
    font-size: 22px;
    font-weight: bold;
    line-height: 16px;
  }

  .text_thre {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0px;
  }

  .sml_text {
    font-size: 12px;
  }

  .border {
    border: 1px solid #4f8491;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .border td {
    border: 1px solid #000;
    padding: 4px;

  }

  .border th {
    border-right: 1px solid #000;
  }

  .text-un-line {
    text-decoration: underline;
  }

  .table_low_pad td {
    padding: 0;
  }

  .table_styl_cb td {
    height: 30px;
    vertical-align: bottom !important;
  }

  .body_all {
    max-width: 1000px;
    margin: 0 auto;
  }

  .body-class section>.adition_page {
    background: #fff;
    padding: 20px !important;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: block;
  }

  .body-class section>.adition_page3 {
    background: #fff;
    padding: 20px !important;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: block;
    min-height: 640px;
  }

  @media screen and (max-width:600px) {
    .body-class section>.adition_page3 {
    
      min-height: 840px;
    }
  }

  .adition_page2 p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .adition_page2 ul li {
    font-size: 14px;
  }
  
  .border-1 {
    border: 1px solid #000000;
    border-collapse: collapse;
  }

  .txt-1 {
    text-align: center;
  }

  .txt-2 {
    padding-left: 120px;
    color: red;
    padding-top: 20px;
  }

  .txt-3 {
    padding-left: 100px;
  }

  .dotted-underline {
    border-bottom: dotted;
  }

  .ft_col {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .colb {
      text-align: right;

    }

  }

  .pt-logo {
    text-align: right;
  }

  .border .tdp {
    text-align: center;
    padding: 4px 2px !important;
  }

  .questh2 {
    text-align: center;
    margin-bottom: 35px;
    margin-top: 20px;
    color: #2680d5;
    font-size: 24px;
  }

  .customer_border {
    border: 1px solid #000 !important;
  }


  .pdf-section {
    background-color: #fff;
    -webkit-box-shadow: 0px 4px 7px 0px #00000082;
    -moz-box-shadow: 0px 4px 7px 0px #00000082;
    box-shadow: 0px 4px 7px 0px #00000082;
    min-height: 500px;
    padding: 60px !important;
    margin-bottom: 15px;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 794px !important;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  body {
    background-color: #525659 !important;
    /* margin-top:0.5cm;
  margin-left: 1.5cm;
  margin-right: 1.5cm; */
    font-family: Arial, Helvetica, sans-serif;
  }

  table {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  table tbody {
    width: 100%;
  }

  .page-break {
    page-break-after: always;
  }

  .m_text_one {
    font-size: 38px;
    font-weight: bold;
    line-height: 20px;
  }

  .m_color_one {
    color: #271c6f;
  }

  .m_text_thre {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0px;
  }

  p {
    margin: 0px;
    font-size: 14px;
    padding: 0px;
    color: #000;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .padding {
    padding: 0px 0px !important;
  }

  .m_text_two {
    font-size: 22px;
    font-weight: bold;
    line-height: 16px;
  }

  .m_f-wb {
    font-weight: bold;
  }

  .m_border {
    border: 1px solid #4f8491;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .m_border td {
    font-size: 12px;
    border: 1px solid #000;
    padding: 4px;
  }

  .m_sml_text {
    font-size: 12px;
  }

  .frst-page-img {
    width: 380px;
    float: right;
  }

  .logo-align {
    text-align: right;
  }

  .complaint {
    clear: both;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .subhead {
    margin-bottom: 10px;
  }

  .mar-btm-20 {
    margin-bottom: 20px !important;
  }

  .mar-btm-5 {
    margin-bottom: 5px !important;
  }

  .mar-top-5 {
    margin-top: 5px;
  }

  .mar-btm-30 {
    margin-bottom: 30px;
  }

  .mar-top-30 {
    margin-top: 30px;
  }

  .mar-top-10 {
    margin-top: 10px !important;
  }

  .mar-top-20 {
    margin-top: 20px !important;
  }

  .declar {
    font-size: 14px;
    margin-bottom: 50px;
  }

  .subtext {
    font-size: 27px;
  }

  .w-50 {
    width: 50%;
  }

  .f-16 {
    font-size: 16px;
  }

  .form-table {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-collapse: collapse;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .name {
    width: 17%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    height: 30px;
    padding: 0px 5px;
  }

  .enter-name {
    width: 33%;
    height: 30px;
    padding: 0px 5px;
  }

  .ryt-border {
    border-right: 1px solid #000 !important;
  }

  .top-border {
    border-top: 1px solid #000 !important;
  }

  .btm-border {
    border-bottom: 1px solid #000;
  }

  .lft-border {
    border-left: 1px solid #000;
  }

  .date-table {
    border-collapse: collapse;
    margin: 0px;
    padding: 0px;
    color: #000;
  }

  .date-table td {
    border-right: 1px solid #000;
    height: 30px;
    vertical-align: bottom;
    text-align: center;
    font-size: 11px;
  }

  .date-table-1 td {
    border-right: 1px solid #000;
    height: 47px;
    vertical-align: bottom;
    text-align: center;
    font-size: 11px;
  }

  .no-border {
    border-right: none !important;
  }

  .no-btm-border {
    border-bottom: none !important;
  }

  .no-lft-border {
    border-left: none !important;
  }

  .no-top-border {
    border-top: none !important;
  }

  .add-table {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-collapse: collapse;
    border: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .add-table-2 {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-collapse: collapse;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .add-td {
    width: 17%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 100px;
    padding: 0px 5px;
  }

  .add-td-blank {
    width: 83%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 100px;
    padding: 0px 5px;
  }

  .location-table {
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-collapse: collapse;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .blank-td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 30px;
  }

  .enter-email {
    width: 83%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 30px;
    padding: 0px 5px;
  }

  .rowi {
    display: flex;
    justify-content: space-between;
  }

  .rowi p {
    font-size: 11px;
    color: #000;
  }

  .float {
    float: right;
  }

  .p-30 {
    padding-top: 30px;
  }

  .align-ryt {
    text-align: right;
  }

  .cont-selection {
    width: 60%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 5px;
    height: 40px;
  }

  .h-40 {
    height: 40px !important;
  }

  .h-50 {
    height: 50px;
  }

  .select-tik {
    width: 40%;
    height: 40px;
    padding: 5px;
    border-bottom: 1px solid #000;
  }

  span img {
    margin-top: 5px;
  }

  .question-td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 5px;
    height: 40px;
  }

  .no-ans {
    padding: 0px 10px;
    vertical-align: top;
    height: 70px;
  }

  .m-0 {
    margin: 0 !important;
  }

  .sml-content {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #45484f;
    margin-top: 0px;
  }

  .question-big {
    width: 40%;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 5px;
    vertical-align: top;
    height: 100px;
  }

  .tik-points {
    width: 60%;
    padding: 5px;
    border-bottom: 1px solid #000;
    height: 100px;
  }

  .charity {
    padding-left: 32px
  }

  .llp {
    padding-left: 71px
  }

  .p-100 {
    padding-top: 160px;
  }

  .m-40 {
    margin-bottom: 40px;
  }

  .emp-det {
    background: #f7f7f7;
    height: 50px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 25%;
  }

  .ptr-det {
    background: #f7f7f7;
    height: 50px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 20%;
  }

  .fill-td {
    height: 50px;
    border-right: 1px solid #000;
    padding: 5px;
  }

  .amt-turn {
    background: #f7f7f7;
    height: 25px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 173px;
  }

  .amt-td {
    height: 25px;
    border-right: 1px solid #000;
  }

  .txt-cent {
    text-align: center;
  }

  .pd-5 {
    padding: 5px;
  }

  .balance-td {
    background: #f7f7f7;
    height: 25px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 173px;
  }

  .emty-td {
    height: 25px;
    padding: 5px;
    width: 192px;
    border-top: none;
    background: #f7f7f7;
  }

  .ques-2 {
    background: #f7f7f7;
    height: 50px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 25%;
  }

  .h-30 {
    height: 30px;
  }

  .link-ptr {
    background: #f7f7f7;
    height: 30px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 25%;
  }

  .an-inc {
    background: #f7f7f7;
    height: 30px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 40%;
    border-bottom: 1px solid #000;
  }

  .an-inc-td {
    height: 30px;
    border-right: 1px solid #000;
    padding: 5px;
    border-bottom: 1px solid #000;
  }

  .asset {
    background: #f7f7f7;
    height: 30px;
    border-right: 1px solid #000;
    padding: 5px;
    width: 40%;
  }

  .big-txt {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 26px;
    color: #271d6f;
    margin-bottom: 0px;
    font-weight: bold;
    margin-top: 20px;
  }

  .name-2 {
    width: 50%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 30px;
    padding: 5px;
  }

  .name-2-blank {
    width: 50%;
    height: 30px;
    border-bottom: 1px solid #000;
    padding: 5px;
  }

  .ad-2 {
    width: 50%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    height: 100px;
    padding: 5px;
    vertical-align: top;
  }

  .ad-2-blank {
    width: 50%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    height: 100px;
    padding: 5px;
    vertical-align: top;
  }

  .ques-3 {
    width: 60%;
    border-right: 1px solid #000;
    background: #f7f7f7;
    height: 30px;
    padding: 5px;
  }

  .ans2 {
    height: 30px;
    width: 33%;
    padding: 5px;
  }

  .add-new {
    width: 100%;
    border-collapse: collapse;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
  }

  .ques4 {
    width: 60%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    height: 30px;
    padding: 5px;
  }

  .date2 {
    width: 33%;
    height: 40px;
    padding: 0px;
  }

  .date2-table {
    border-collapse: collapse;
    margin: 0px;
    padding: 0px;
    color: #000;
  }

  .date2-table td {
    border-right: 1px solid #000;
    height: 70px;
    vertical-align: bottom;
    text-align: center;
  }

  .table-4page {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    padding: 0px;
    border: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
  }

  .ques-td {
    width: 70%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 40px;
    padding: 5px;
  }

  .img-td {
    width: 25%;
    height: 50px;
    padding: 5px;
    border-bottom: 1px solid #000;
  }

  .ques5 {
    width: 70%;
    background: #f7f7f7;
    border-right: 1px solid #000;
    height: 30px;
    padding: 5px;
  }

  .date-again {
    width: 25%;
    height: 30px;
    padding: 0px 5px;
  }

  .date-again table {
    border-collapse: collapse;
    margin: 0px;
    padding: 0px;
    color: #000;
  }

  .date-again table td {
    border-right: 1px solid #000;
    height: 50px;
    vertical-align: bottom;
    text-align: center;
    font-size: 11px;
  }

  .sml-table {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 0px;
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #45484f;
  }

  .sml-table td {
    height: 140px;
    padding: 5px;
    font-size: 15px;
    vertical-align: top;
  }

  .sml-table-2 {
    width: 100%;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-collapse: collapse;
    padding: 0px;
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #45484f;
  }

  .box-1 {
    width: 50%;
    height: 40px;
    padding: 5px;
    border-right: 1px solid #000;
    background: #f7f7f7;
  }

  .box-2 {
    width: 50%;
    height: 40px;
    padding: 5px;
  }

  .sml-2 {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #45484f;
  }

  .sml-2 td {
    height: 300px;
    padding: 10px;
    vertical-align: top;
  }

  .p-70 {
    padding-top: 70px;
  }

  .page5-table {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #000;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .page5-table td {
    height: 350px;
    padding: 5px;
    vertical-align: top;
  }

  .page5-table2 {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #000;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;
  }

  .page5-table2 td {
    height: 300px;
    padding: 15px;
    vertical-align: top;
  }

  .p-25 {
    padding-top: 15px;
  }

  .table-last-6 {
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 0px;
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #45484f;
  }

  .add-last-6 {
    padding: 5px;
    height: 120px;
    vertical-align: top;
    width: 40%;
    background: #f7f7f7;
    border-right: 1px solid #000;
  }

  .add-last-blank {
    padding: 5px;
    height: 120px;
    width: 60%;
    vertical-align: top;
  }

  .w-40 {
    width: 48%;
  }

  .w-60 {
    width: 60% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .h-42 {
    height: 40px !important;
  }

  .h-change {
    height: 47px;
  }

  .pg-break-nw {
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .bg-white-new {
    margin-bottom: 55px;
  }
  .container-1{ 
    padding: 0px 80px 80px 80px;
    // width: 70%;
    margin: 120px auto;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    min-height: 1180px;
    max-width: 1072px;

    .text_head{
      font-size: 20px;
      color: #000;
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
    }
}

.logo-img { 
  width: 280px;
}

.date_text{ 
  padding-left:120px; 
  margin-top:60px;
}
}
.loa-sec-2{ 
  min-height: 100vh; 
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 600px) {
 
  .container-1{ 
    padding: 1%;
    width: 98%;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    background-color: white;
    min-height: 500px;
}

.date_text{ 
  padding-left:30px !important; 
  margin-top:10px !important;
  text-align: left !important;
}
.logo-img{ 
  width: 255px;
}

.ps-5,.px-5{ 
  padding: 0px !important;
}


.my-5{ 
  margin: 0 !important;
}
}

@media only screen and (min-width: 960px) {


  .loa-sec {

    .log_hd {
      width: 380px;
      float: right;

    }

  }


}


@media only screen and (max-width: 960px) {
  .loa-sec {
    .ft_col {
      display: block;

      .colb {
        margin-top: 20px;

      }
    }
  }

}


@media only screen and (max-width: 768px) {
  .c-address {
    width: 100% !important;
  }
}


@media only screen and (max-width: 590px) {

  .loa-sec {

    .log_hd {
      .br_code {
        width: 50px;
      }
    }

    .pt-logo {
      text-align: left;
    }

  }

}

@media only screen and (max-width:410px) {

  .loa-sec {
    .table_resp {
      width: 300px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:414px) {

  .loa-sec {
    .table_resp {
      width: 350px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:393px) {

  .loa-sec {
    .table_resp {
      width: 317px;
      overflow-x: scroll;
    }

  }
}


@media only screen and (max-width:375px) {

  .loa-sec {
    .table_resp {
      width: 317px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:360px) {

  .loa-sec {
    .table_resp {
      width: 290px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:320px) {

  .loa-sec {
    .table_resp {
      width: 260px;
      overflow-x: scroll;
    }

  }
}
@media (max-width: 500px){
  .w-40 {
      width: 70% !important;
  }
}

@media (max-width: 500px){
  .table-last-6 {
      font-size: 8px !important;
  }
  .h-change {
    height: 38px !important;
}
.loa-sec {
  .txt-3 {
    padding-left:50px;
  }
  .txt-2 {
    padding-left:60px;
  }
  .ft_col_last{
    padding: 50px 20px;
  }
}

}


 

 










