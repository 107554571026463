.PreviewOneVaq_V5{
  scroll-snap-type: y mandatory;

  .inner-box-preview {
    height: 350px;
    overflow-y: auto;
    /* padding: 0px 10px; */
    border: none;
    color: #5b5b5b;
    margin-bottom: 25px;
    // margin-left: 10px;
    // margin-right: 10px;
  }
  .PreviewPageV5 {
    background: #ffffff !important;
  }
  .PreviewPageV5 {
    padding: 50px 0 100px 0 !important;
    @media (max-width: 568px) {
      padding: 15px 0 100px 0 !important;
    }
    .preview_headline {
      font-size: 22px;
      font-weight: 700;
      line-height: 130%;
      color: #2f2f2f;
      text-align: center;
      @media (max-width: 568px) {
        font-size: 19px;
      }
    }
  .textwrap{
    border: 1px solid #000;
    border-radius: 5px;
    margin-bottom: 10px !important;
    display: block;
  }
    .green_subtext {
      color: #03bc03;
      font-style: italic;
      font-weight: 600;
      font-size: 17px;
      @media (max-width: 568px) {
        font-size: 13px;
      }
    }
    .anim_ylw_preview {
      transition: all ease-in 0.5s;
      animation: pulse2 1500ms infinite;
      @keyframes pulse2 {
        0% {
          box-shadow: #ef8019 0 0 0 0;
        }
        75% {
          box-shadow: #ff69b400 0 0 0 16px;
        }
      }
    }
    padding-top: 50px;
    background: #eee;
    .important-margin {
      margin-top: 0px !important;
    }
    .container-top {
      // padding: 80px 80px 80px 80px;
      width: 64%;
      margin: 0 auto;
      text-align: center;
      // font-family: "Noto Sans TC", sans-serif;
      background-color: white;
    }
    // .container-1 {
    //   padding: 80px 80px 80px 80px;
    //   width: 64%;
    //   margin: 120px auto;
    //   font-family: "Noto Sans TC", sans-serif;
    //   background-color: white;
    //   min-height: 1180px;
    // }
    .container-1 {
      padding: 1%;
      width: 94%;
      padding: 3px 16px;
      margin: 0px auto !important;
      min-height: 500px;
    }
    .sec_loa p,
    .sec_loa li {
      font-size: 10px !important;
    }
    .loa_header_address {
      font-size: 11px;
      padding-left: 82px;
      font-weight: 400;
    }
    .logo_imgloa {
      max-width: 30%;
    }
    .loa_h1 {
      font-weight: 800;
      font-size: 24px;
      margin-top: 12px;
    }
    .loa_table1 td {
      border: 1px solid black;
      border-collapse: collapse;
      padding: 6px;
      font-size: 12px;
      text-align: center;
    }
    .table_1a {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      margin-bottom: 12px;
      width: 100%;
    }
    .table_1a td {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      padding: 4px;
      font-size: 10px;
    }
    h5 {
      font-size: 15px;
      font-weight: 900;
    }
    h4 {
      font-size: 18px;
      color: #000;
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      // font-size: 14px;
      font-size: 12px;
      font-weight: 600;
    }
    li {
      font-size: 14px;
      padding-right: 10px;
      font-weight: 500;
      margin-top: 18px;
    }
    .next-btn {
      position: fixed;
      bottom: 0px;
      width: 100%;
      text-align: center;
    }
    .first_td {
      height: 1200px;
      vertical-align: top;
    }
    .table_maxfee,
    .table_maxfee td {
      border: 1px solid black;
      border-collapse: collapse;
      font-size: 13px;
    }
    .table_maxfee td {
      width: 25%;
      padding: 6px;
      text-align: center;
    }
    .table_feeeg td {
      border: 1px solid black;
      font-weight: 700;
      border-collapse: collapse;
      font-size: 13px;
      padding: 4px;
    }
    .dsar-sec {
      table {
        width: 100%;
      }
      table tbody {
        width: 100%;
      }
      p {
        margin: 0px;
        font-size: 12px;
        padding: 0px;
        line-height: 13px;
        font-weight: 400;
      }
      .table_1,
      .table_1 td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        font-size: 13px;
      }
      h5 {
        font-size: 12px;
      }
      ol {
        padding-top: 0;
        padding-bottom: 0;
      }
      ol li {
        font-size: 10px;
        margin-bottom: 12px;
      }
      .table_1a {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        margin-bottom: 12px;
      }
      .table_1a td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: center;
        padding: 4px;
        font-size: 10px;
      }
    }
    .t-table {
      display: flex;
      flex-wrap: wrap;
    }
    .table-sec {
      min-height: auto;
      padding-top: 80px;
    }
    .t-table_div {
      width: 45%;
      margin: auto;
      border: 1px solid #000;
      padding: 25px 15px;
      border-radius: 30px;
    }
    .logo-tab {
      width: 100%;
      float: right;
    }
    .color_one {
      color: #271c6f;
    }
    .f-wb {
      font-weight: bold;
    }
    .border {
      border: 1px solid #000;
      border-collapse: collapse;
      border-spacing: 0;
    }
    .border td {
      border: 1px solid #000;
      padding: 4px;
    }
    .border th {
      border-right: 1px solid #000;
    }
    .declare-sec h4 {
      text-align: left;
    }
    .declare-sec p {
      font-weight: normal;
    }
    .important-font-size {
      font-size: 11px !important;
      line-height: 14px;
      font-weight: 400;
    }
    /*  **** MEDIA QUERIES **** */
    @media only screen and (max-width: 600px) {
      .container-1 {
        padding: 1%;
        width: 100%;
        padding: 24px 10px;
        margin: 0px auto !important;
        font-family: "Poppins", sans-serif !important;
        min-height: 500px;
      }
      .container-top {
        padding: 0px;
        width: 100%;
        // padding: 24px 16px;
        margin: 0 auto !important;
      }
      .loa_header_address {
        padding-left: 25px;
      }
      .logo_imgloa {
        max-width: 90%;
      }
      .h5 {
        font-size: 13px;
        font-weight: 900;
      }
      .first_td {
        height: auto;
      }
      .ps-5,
      .px-5 {
        padding: 0px !important;
      }
      li {
        font-size: 12px;
      }
      h5 {
        font-size: 16px;
      }
      p {
        font-size: 11px;
      }
    }
    ul,
    li {
      list-style-type: revert;
    }
  }

.hide {
  display: none;
}

.show {
  display: block;
}

.blackover {
  background: rgba(0, 0, 0, .98) !important;
}

.goodnews_popup {
  padding: 1rem;
  background: #fff;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 12px !important;
  .title {
    .textgood {
      font-size: 2rem;
    }
  }


  .listfi {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0 25px;
    gap: 0 25px;
  }

  .listfi li {
    width: 48%;
    border-bottom: 1px solid #cdcdcd;
    padding: 4px 0;
    display: flex;
    grid-gap: 7px;
    gap: 5px;

    a {
      color: #000;
      text-decoration: underline !important;
      font-size: 14px;
    }

  }

  .listfi {
    li:before {
      background: url("../img/poptick.png") no-repeat;
      background-size: 20px;
      width: 20px;
      height: 20px;
      content: "";
      display: inline-block;
    }

  }

  h4 {
    font-size: 1.3rem;
  }

  .text-arrow {
    color: #000;
  }

  p {
    font-size: 12px;
  }
}


.close22{
  position: absolute;
  top: 0;
  margin: 0px 0 0 -20px;
  height: 50px;
  width: 50px;
}

.flex-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  position: sticky;
  right: 15px;
  // top: 15px;
}
[class^="item"]{
  text-decoration: none;
  margin-right: 20px;
  background: transparent;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
}
.item-1 {
  height: 50px;
  .inner {
    display: block;
    position: relative;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 0 0 0px;
    border-radius: 10px;
    &:before {
      content: "";
      position: absolute;
      height: 6px;
      width: 35px;
      border-radius: 20px;
      background-color: #ec272f;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: ease-out .2s all;
    }
    &:after {
      content: "";
      position: absolute;
      height: 6px;
      width: 35px;
      border-radius: 20px;
      background-color: #ec272f;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: ease-out .2s all;
    }
  }
  .label {
    color: #ec272f;
    opacity: 0;
    transition: all .2s ease-out;
    font-size: 20px;
    font-weight: 700;
  }
  &:hover,
  &:focus {
    .inner {
      &:before {
        transform: translate(-50%, 0%) rotate(0);
        top: 13%;
      }
      &:after {
        transform: translate(-50%, 0%) rotate(0);
        top: 76%;
      }
    }
    .label {
      opacity: 1;
    }
  }
}


.signinpopup {
  .web-canvas {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin: auto;
    padding: 10px;
  }

  h2 {
    font-size: 26px;
    color: #004c70;
    margin-bottom: 15px;
  }

  .popup-sign-sec {
    .sign-check {
      font-size: 13px;
      text-align: justify;
    }

    .signbutton {
      background-color: #ef453d;
      color: #fff;
      padding: 10px 20px;
      border: 0;
      border-radius: 7px;
      min-width: 110px;
    }
  }

  ul {
    list-style-type: none;
    text-align: center;
    margin: 10px 0 20px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    li {
      border: 0 !important;
      margin: 7px 5px !important;

      a {
        list-style: none;
        padding: 5px 10px;
        background-color: #e7e6e6;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }

}

li {
  font-size: 14px;
  padding-right: 10px;
  font-weight: 500;
  margin-top: 18px;
  list-style-type: revert-layer I !important;

}


@media only screen and (max-width: 600px) {

  .goodnews_popup .listfi li {
    width: 100%;
    padding: 4px 0;
  }

}
}