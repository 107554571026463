
.QuestionaireV29{


/*================================================
03 - BODY STYLES
==================================================*/
* {
  outline: none;
}

$color1: #3a8ddb;
$color2: #1891a5;
$color3: #1b1b1b;
$color4: #1fccf2;
$color5: #df4514;
$color6: #1fccf2;
$color7: #df2c2c;
$color8: #ef453d;
$color9: #1ed1bc;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946fff;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;

$font_family_1: "Poppins", sans-serif !important;

$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

body {
  font-family: $font_family_1 !important;
 
}

.banner {
  &.qs-section {
    min-height: 100vh;
    height: auto;
  }
}

input[type="radio"] {
  display: none;
}


.que-title{
  color: #000;
}

.active{
  .que-title{
    color: #9b9b9b !important;
  }

}


.questionnair-section {
  padding-left: 10rem;
  position: relative;

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    background: #ffffff24;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(77px);
  }

  .questitem {
    background: $white-color;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;


    &::before {
      content: "";
      width: 25px;
      height: 25px;
      background: $white-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translateX(-95px);
      transition: all ease-in 0.5s;
      animation: pulse 1500ms infinite;

      @keyframes pulse {
        0% {
          box-shadow: #59d4fa 0 0 0 0;
        }

        75% {
          box-shadow: #ff69b400 0 0 0 16px;
        }
      }


    }

    &.active {
      &::before {
        background: $color8 url("../img/check.png") no-repeat;
        transition: all ease-in 0.5s;
        background-position: center center;
        animation: initial;
      }
    }

    h1 {
      font-size: 1.1rem;
      margin: 0;
      // color: #9b9b9b;
      line-height: 30px;

      &.text-style {
        color: #000 !important;
        // text-decoration: line-through;
      }

      &.text-finish {

        color: #9b9b9b !important;
      }
    }

  }

  fieldset {
    border: 1px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }

  legend {
    width: auto;
    padding: 2px 10px;
    font-size: 16px;
    font-weight: 400;
    float: none;
    text-align: left;
  }

  input,
  select {
    border-color: #d1d1d1;
    font-size: .9rem;
    height: 100%;
    min-height: 45px;
  }

}

.ques_titleTag{
  font-size: 1.7rem !important;
  font-weight: bold !important;
  line-height: 130% !important;
  @media (max-width: 768px) {
    font-size: 1.18rem !important;
  }
}

.op_btn{
  label {
    display: flex !important;
    align-items: center !important;
    justify-content: normal !important;
  }
}

.adjustbalewidth {
  width: 30% !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.optionbtn {
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
  }

//   span {
//     width: 20px;
//     height: 20px;
//     display: inline-block;
//     position: absolute;
//     border-radius: 50%;
//     border: 2px solid #1fccf2;
//     left: 7px;
// }

  // input:checked+label span {
  //   background: #306fce url(../img/tick-1.png) center no-repeat;
  //   background-size: 20px;
  //   border: 2px solid #306fce;
  // }

  input:checked+label {
    background: $color4;
    color: $white-color;
  }

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    position: relative;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid $color4;
  }
}


.option-grid {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;

  li {
    width: 24%;

    .optionbtn {
      label {
        width: 100%;
        text-align: center;
      }
    }
  }

  .adjustbalewid {
    width: 19%;
  }

  .adjustbalewidfull {
    width: 100%;

    .optionbtn {
      label {
        padding: 7px 40px;
        text-align: left;
      }
    }
  }

  .adjustbalewidhalf {
    width: 49% !important;
  }
}

.popupclaim {
  h4 {
    font-size: 1.2rem;
  }
}

.loader-wrap {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-55 {
  height: 55px;
}

.shadow-style {
  transition: all ease-in 0.5s;
  animation: pulse2 1500ms infinite;

  @keyframes pulse2 {
    0% {
      box-shadow: #ef8019 0 0 0 0;
    }

    75% {
      box-shadow: #ff69b400 0 0 0 16px;
    }
  }
}

.yespop{
  font-weight: normal !important;
  background: #30bd31 !important;
  &:hover {
    transition: all 0.3s ease;
    background: #298b29 !important;
  }
  &:after {
    background-color: #298b29 !important;
  }
}

.nopop{
  background: #999ea3 !important;
  &:hover {
    transition: all 0.3s ease;
    background: #6f7172 !important;
  }
  &:after {
    background-color: #6f7172 !important;
  }
}
.popmodalbg{
  background:url("../img/secure-pop.png") no-repeat center center; background-size: cover; min-height: 200px;
}

//////////////////////////////////////////////////////////////////////




$diagonal: 26px; // 13px arrow
$size: $diagonal / 1.414; // Формула диагонали квадрата, d = sqrt(2)*a;
$half: (
  $diagonal / 2
);
$halfNegative: (
  $diagonal / -2
);

.tipbox {
  width: 100%;
  height: auto;
  position: relative;
  background: #012216;
  margin-top: 10px;
  color: #fff;
  padding: 10px;
  border-radius: 7px;
  font-size: 15px;
  text-align: center;
  animation: pulse 1500ms infinite;
  margin-bottom: 15px;
}

.arrow {
  position: absolute;
  overflow: hidden;

  &:after {
    content: '';
    background: #012216;
    width: $size;
    height: $size;
    position: absolute;
    border: 2px solid #012216;
    transform-origin: 0 0;
  }
}

.arrow-top {
  width: $diagonal;
  height: $half;
  left: 10%;
  top: $halfNegative;
  margin-left: $halfNegative;

  &:after {
    left: $half;
    top: 0;
    transform: rotate(45deg);
  }
}

.qrange {
  position: fixed;
  z-index: 9;
  top: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  vertical-align: middle;
  background-color: rgba(0, 209, 3, 0.853);
  cursor: pointer;
  justify-content: center;
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  transition: all 0.35s cubic-bezier(.91, -0.28, .06, 1.3), background-color 0.25s ease;

  &:hover {
    background-color: #099c0b;
  }




}



// 
.height-scale {
  -webkit-animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-down-ver-center {
  -webkit-animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: .3s;
}

////////////////////////// Keyframe ////////////////////////////////////////////

@-webkit-keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {

    -webkit-transform: scaleY(1);
    transform: scaleY(1);

  }
}

@keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.fade {
  background: #000000db;
}

.que-title {
  font-size: 1.1rem;
  margin: 0;
  // color: #9b9b9b;
  line-height: 30px;
}

.text-style  {
  color: #000 !important;
}
.que-title-sub {
  font-size: 20px !important;
  margin: 0;
  color: #9b9b9b;
  line-height: 30px;
}

@media screen and (max-width: 579px) {
.que-title  {
      font-size: 1rem;
      line-height: 24px;
  }
  .que-title-sub {
    font-size:17px !important;
  }
}


.otp-input {
  display: flex;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  input {
    width: 12% !important;
    border-color: #d1d1d1;
    font-size: .9rem;
    height: 85px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 12px !important;
    @media only screen and (max-width: 768px) {
        width: 12% !important;
        height: 40px !important;
        min-height: 40px;
  }
}
}
.otp-input-field {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color .2s;
}

.btn-green-skip {
  background-color: rgba(24, 206, 15, .8) !important;
  color: #fff !important;
  width: 100% !important;
  height: 100% !important;
}

.warning-text {
  font-size: 13px;
  font-style: italic;
  color: red;
  text-align: center;
}


////////////////////////// Keyframe ////////////////////////////////////////////


@media screen and (min-width: 960px) {
  .form-style {
    select {
      height: 100%;
    }
  }
}

@media screen and (max-width: 579px) {
  .qrange {
    bottom: 0;
    width: 100%;
    right: 0;
    top: inherit;
    border-radius: 0;
    height: 50px;
    line-height: 50px;
  }

  .questionnair-section {
    padding-left: 2rem;

    &::before {
      transform: translateX(8px);
    }

    .questitem {
      h1 {
        font-size: 1rem;
        line-height: 24px;

      }

      &::before {
        transform: translateX(-29px);
        width: 18px;
        height: 18px;

      }

      &.active {
        &::before {
          background-size: 12px;
        }
      }

    }

    legend {
      font-size: 13px !important;
    }
  }

  .option-grid {
    justify-content: center;

    li {
      width: 47%;

      .optionbtn {
        label {
          padding: 15px 5px;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }

    .adjustbalewid {
      width: 48% !important;
    }

    .adjustbalewidfull {
      width: 100% !important;
    }

    .adjustbalewidhalf {
      width: 100% !important;
      padding-right: 0 !important;
    }
  }

  .input-mobile {
    width: 100%;
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-radius: 0;
    font-size: 12px !important;
  }

  .input-group>.form-control {
    border-radius: 0 !important;
    margin-left: 0px;
  }

  .btn-comm2 {
    padding: 20px 10px !important;
  }






}
}