// Custom.scss

@import "./modules/custom.scss";

// Option A: Include all of Bootstrap

@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
@import '../../../../node_modules/swiper/swiper-bundle.min.css';
@import url("../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css");


 