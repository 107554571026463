.UFC_DC_V1{
$color1: #3A8DDB;
$color2: #1891a5;
$color3: #1B1B1B;
$color4: #1FCCF2;
$color5: #BD3333;
$color6: #1FCCF2;
$color7: #00BD8E;
$color8: #EF8019;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;
$white-color: #fff;
$black-color: #000;


.forms-body {
  border-radius: 30px;
   &.forms-body1 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
   }
   &.forms-body2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
   }
  .main-title {
    font-size: 20px;
  }

  .title {
    font-size: 18px;
  }

  fieldset {
    border: 1px solid #d1d1d1;
    border-radius: 10px;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 500;
    }
  }



  input,
  select {
    border: 1px solid #A3A3A3;
    font-size: 14px;
    height: 55px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    &:focus {
      box-shadow: rgba(247, 176, 44, 0.3) 0px 0px 0px 3px;
    }
  }

  .field-secure {
    background: $white-color url("../../img/guaranteedprivacy.png") no-repeat right center;
    background-size: 90px;
    padding-right: 100px;
  }

  .form-style {


    .sign-sec {
      h5 {
        font-size: 16px;
      }

      .clearbutton {
        background: $color1;
        border: none;
        outline: 0;
        font-size: 14px;
        padding: 6px 15px;
        border-radius: 5px;
        color: $white-color;
      }


      .signbox {

        canvas {
          width: 100%;
          height: 200px;
          border: 1px solid #d5d5d5;
          border-bottom: 2px dotted $black-color;
          ///background: $white-color url(../img/signhere.png) no-repeat center center;
        }



      }

    }

    .sign-check {
      .checkinput {
        width: 20px;
        height: 20px;
        position: relative;
        top: 6px;

      }

      label {
        font-size: .8rem;
      }

      text-align: justify;
    }

    p {
      font-size: 13px;
    }

    .list-point {
      li {
        padding-bottom: 6px;
        font-size: 13px;
        position: relative;
        padding-left: 26px;

        &::before {
          content: "";
          //background: url("../img/arrows_more_up.png")no-repeat;
          background-size: 18px;
          content: "";
          display: block;
          width: 25px;
          height: 25px;
          position: absolute;
          left: 0;

        }
      }
    }
  }


}

.bg-primary-subtle {
  background: #dcffd7 !important;
  color: #46743e;
}

@media screen and (max-width: 460px) {

  .inner-section {
    padding: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    .inner {
      padding: 20px;

      .qs-title{
        font-size: 17px;
    line-height: 27px;
      }
    }
    .hd-lop {
       img {
        height: 50px;
       }
       h1 {
        font-size: 16px;
       }
    }
  }
  .sub-text {
    font-size: 13px;
  }

  .below-text {
    font-size: 11px;
    img {
      height: 24px;
    }
  }

  .btn-option{
    &.optinal-items{
      &.two-options {
        gap: 15px;
      }
      .option_but {
        label {
          padding: 10px 10px 10px 10px;
        }
      }
    }
  }


  .back-btn {
    font-size: 16px;
  }

  .forms-body{
    input {
      height: 50px;
    }
  }


}
}


