.selected-options {
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 6px 12px -2px rgba(50, 50, 93, .25), 0 3px 7px -3px rgba(0, 0, 0, .3);
    // border: 1px solid #1fccf2;
    background: #24b5d5;
    color: #fff;

}

.selected-options p {
    margin-bottom: 0px;
    flex: 1;
    padding: 5px 0px;

    &.label {
        margin: 5px 10px;

    }

    @media (max-width: 768px) {
        padding: 5px 0px;
        font-size: 12px;
    }

}

.elavate{
    z-index: 11111;
    position: relative;
}

.error_msg {
    color: #fff;
    width: 100%;
    float: left;
    background: red;
    font-size: 13px;
    padding: 0 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 10px;
}

.delete-option {
    background-color: #cb0000;
    color: white;
    cursor: pointer;
    height: auto;
    justify-content: center;
    border-end-end-radius: 4px;
    border-top-right-radius: 4px;
    border: none;
}