.ReviewSubmit {

  .blackover {
    background: rgba(0, 0, 0, .98) !important;
    top: 0% !important;
  }

  /*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 18-01-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

  /*================================================
01 - Sass Variables
==================================================*/

  // =======background colors========

  $color1: #3385BA;
  $color2: #EF1E21;
  $color3: #EFFAFF;
  $color4: #1FCCF2;

  $black-color: #000;
  $white-color: #fff;



  $font_family_1: "Poppins", sans-serif !important;



  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $font-w600: 600;
  $font-w700: 700;


  /*================================================
02 - Sass Mixins
==================================================*/


  body {
    font-family: $font_family_1;
  }

  .btn-comm {
    background: $color2;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: $white-color;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    border: none;

    &:disabled {
      background: #b1b0b0;
    }

    // &:hover {
    //   background: $black-color;
    //   color: $white-color;

    //   &::after {
    //     transform: translateX(0%);
    //     transition: all ease-in 0.3s;
    //   }


    // }
  }

  .prev-popup {
    h4 {
      font-size: 21px;
      line-height: 24px;
    }
  }
  .goodnews_popup {
    min-height: 400px;
  
    .title {
      font-size: 1rem;
  
      .textgood {
        font-size: 2rem;
      }
    }
  
    h4 {
      font-size: 1.3rem;
    }
  
    p {
      font-size: 14px;
    }
  
    .btn-comm2 {
      font-size: 1.2rem;
      background: #ef453d;
  
      &:after {
        background: #e5362e;
      }
    }
  
    .text-arrow {
      position: relative;
      display: inline-block;
  
      .st-arrow {
        position: absolute;
        right: 0;
        bottom: -54px;
      }
    }
  
    .max-400 {
      max-width: 600px;
    }
  
    .h4-thakyou {
      line-height: 26px;
    }
  
    .listfi {
      display: flex;
      flex-wrap: wrap;
      gap: 0 25px;
  
      li {
        padding: 0;
        width: 48%;
        border-bottom: 1px solid #cdcdcd;
        padding: 8px 0;
        display: flex;
        gap: 10px;
  
  
  
        &::before {
          background:  url(../img/tick02.png) no-repeat;
          background-size: 20px;
          width: 20px;
          height: 20px;
          content: "";
          display: inline-block;
        }
      }
    }
  }

  /////////////////////////////////////////


  @media screen and (max-width: 390px) {
    .welc-back {
      img {
        height: 95px;
      }
    }
  }
}