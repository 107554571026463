.confirm_V1Modal{
*,
*::before,
*::after {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   list-style: none;
   list-style-type: none;
   text-decoration: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeLegibility;
}

html {
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}

// body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
// 
$font-primary: 'Poppins', sans-serif;


.fade{
  background: #000000c9;
}
.p{
  font-family: $font-primary;
}

.modal-set-header h2{
  font-size: 18px;
  color: #15479E;
  @media screen and (max-width: 470px) {
        font-size: 18px;
  }
  p {
    font-size: .9rem;
  }
}

.btn-option.ul-agree li {
  width: 100%;
  .option_but {
    font-weight: 600;
    input {
      position: absolute;
      display: none;
    }
    .agree {
      background: #ef453d;
    }

    input:checked+label {
      background: #ff912b;
      color: #fff;
    }

     label {
      padding: 10px;
      display: block;
      border: 1px solid #ef453d;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

@media screen and (max-width: 470px) {
  .modal-set-header p {
      font-weight: 500;
  }
}
}