// .supagebg {
//   background-image: url(../img/sub-page-bg.jpg);
//   background-position: top center;
//   background-repeat: no-repeat;
//   background-size: cover;
// } 



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');

$font_family_1: 'Poppins', sans-serif;

.credheader {
    // background: none;
    box-shadow: none;
    text-align: center;
    padding: 25px 0 25px;
}

.creditanalysis{
    background:url("../img/loaderpagebg-desktop.jpg") no-repeat top center #1891a5;
    background-size: cover;
    min-height:95vh;
    overflow-x: hidden;
    position: relative;
    padding-top:0px;
    justify-content: center;
    h4{
        color: #ffffff;
        font-size: 16px;
        font-family: $font_family_1 !important;

    }
    h5{
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 25px;
    }
    .bor-left-cred{
        border-left: solid 1px #ffffff;
        padding-left: 15px;
    }
    // progressCircle

    //styling
    $borderWidth: 15px;
    $animationTime: 1.5s;
    $border-color-default: #1732a2;
    $border-color-fill: #1dd9b3;
    $size: 150px;

    //Create how many steps
    $howManySteps: 10; //this needs to be even.
    //for fun try using 20 and changine in the HTML the data-percentage to 15 or 85

    .progress {
      width: $size;
      height: $size;
      line-height: $size;
      margin: 20px auto;
      box-shadow: none;
      position: relative;
      background: rgb(33, 69, 221);
      background: linear-gradient(
        129deg,
        rgba(33, 69, 221, 1) 0%,
        rgba(18, 37, 119, 1) 100%
      );
      border-radius: 100%;

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: $borderWidth solid $border-color-default;
        position: absolute;

        top: 0;
        left: 0;
      }

      > span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
      }

      .progress-left {
        left: 0;
      }

      .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: $borderWidth;
        border-style: solid;
        position: absolute;
        top: 0;
        border-color: $border-color-fill;
      }

      .progress-left .progress-bar {
        left: 100%;
        border-top-right-radius: ($size/2);
        border-bottom-right-radius: ($size/2);
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
        //animation: loading-2 1.5s linear forwards 1.8s;
      }

      .progress-right {
        right: 0;

        .progress-bar {
          left: -100%;
          border-top-left-radius: ($size/2);
          border-bottom-left-radius: ($size/2);
          border-right: 0;
          -webkit-transform-origin: center right;
          transform-origin: center right;
          //animation: loading-1 1.8s linear forwards;
        }
      }

      .progress-value {
        display: flex;
        border-radius: 50%;
        font-size: 36px;
        text-align: center;
        line-height: 20px;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        font-weight: 500;
        color: #1dd9b3;

        div {
          margin-top: 10px;
        }

        span {
          font-size: 12px;
          text-transform: uppercase;
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
    .progress-container {
      position: relative;
      width: 170px;
      height: 170px;
      margin: 0 auto;
    }
    
    .progress-container .progress-circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: conic-gradient(#1dd9b3 0%, #1dd9b3 0%, #fff 0%, #fff 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 5s linear;
    }
    
    .progress-container .progress-inner {
      width: 80%;
      height: 80%;
      background: linear-gradient(129deg, #2145dd 0%, #122577 100%);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .progress-container .progress-inner .text2 {
      font-size: 12px;
      color: #fff;
    }
    
    #progress-value {
      font-size: 1.6em;
      color: #1dd9b3;
      line-height: 22px;
    }

    /* This for loop creates the 	necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
    @for $i from 1 through $howManySteps {
      $stepName: ($i * (100 / $howManySteps));

      //animation only the left side if below 50%
      @if $i <=($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
          .progress-right .progress-bar {
            animation: loading-#{$i} $animationTime linear forwards;
          }

          .progress-left .progress-bar {
            animation: 0;
          }
        }
      }

      //animation only the right side if above 50%
      @if $i >($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
          .progress-right .progress-bar {
            animation: loading-#{($howManySteps/2)}
              $animationTime
              linear
              forwards; //set the animation to longest animation
          }

          .progress-left .progress-bar {
            animation: loading-#{$i -
              ($howManySteps/2)}
              $animationTime
              linear
              forwards
              $animationTime;
          }
        }
      }
    }

    //animation
    @for $i from 1 through ($howManySteps/2) {
      $degrees: (180/ ($howManySteps/2));
      $degrees: ($degrees * $i);

      @keyframes loading-#{$i} {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate($degrees);
          transform: rotate(#{$degrees}deg);
        }
      }
    }

    //additional styling
    .progress {
      margin-bottom: 1em;
    }

    // progress end
  }

.crediatailsloader{
    background: #E1EEFF;
    background: linear-gradient(180deg, #E1EEFF 0%, #7B93F6 100%);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 15px;
    // border-top: 7px solid #ffffff;
    min-height: 55vh;
    margin-top: 60px;
    h3{
        margin: 15px 0;
        font-size: 18px;
        font-family: $font_family_1 !important;
    }
    h4{
      margin: 15px 0 25px;
      font-size: 18px;
      color: #000000;
  }
   img{
    margin: 15px 0;
   }
}
.loader-texts{
  position: relative;
  padding: 15px 0;
  display: block;
  min-height: 70px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
}
.loader-text {
  // opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in-out; /* Fade-in and fade-out effect */
  position: absolute;
  display: none; /* Hidden initially */
}

.loader-text.show {
  display: block;
  opacity: 1; /* Fully visible */
}

.credit-option-wrap {
  gap: 10px;
}

.cred-but{
  font-family: 'Poppins', sans-serif !important;
  display: inline-block;
  background: #052195;
  color: #fff !important;
  padding: 15px 20px;
  border-radius: 30px !important;
  border: 0;
  width: 100%;
  min-width: 150px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  margin-left: 0px ;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-align: center;
    @media screen and (max-width: 768px) {
    }
    &:hover{
        background: #1DD9B3;
    }
    
}
.cred-slide1, .cred-slide2 {
  opacity: 1; /* Fully visible */
  transition: opacity 0.5s ease-in-out; /* Transition for smooth fade */
}

.fade-out {
  opacity: 0; /* Fade out effect */
  display: none;
}

.fade-in {
  opacity: 1; /* Fade in effect */
  display: block;
}

@media screen and (max-width: 768px) {
    .creditanalysis{
        background:url("../img/loaderpagebg-mob1.jpg") no-repeat top center #1891a5;
        background-size: cover;
    }
    .cred-but{
      width: 100%;
      min-width: 350px;
      margin-bottom: 10px;
    }

}

@media screen and (max-width: 790px) {
  .progress-container {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 480px) {
  .progress-container {
    width: 150px;
    height: 150px;
  }
}