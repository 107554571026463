.OD_UFL_4_2{
.bg-lightblue {
    background-color: #ffffff !important;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 1rem 1rem 3rem;
}

.lend_text{
    font-size: 22px;
    font-weight: 500;
    padding: 5px 0px 10px;

    span{
        font-weight: 700 !important;
    }
    @media (max-width: 568px) {
        font-size: 18px
    }
}

.lend_subTxt{
    font-size: 15px;
    padding-bottom: 10px;
    @media (max-width: 568px) {
        font-size: 12px
    }
}

.nextLender{
    border-radius: 10px !important;
    font-weight: 700;
}

.sml_checkTxt{
    font-size: 11px;
}

.lenders{
    // selectoptions

.selectoptions{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px 13px;
    @media (max-width: 568px) {
        grid-gap: 0px;
        grid-template-columns: repeat(1, 1fr);
    }
    input[type=checkbox]+label {
        background: url(../../img/uncheck.png) no-repeat left center;
        background-position:15px;
        background-size: 20px;
        // border-radius: 30px;
        background-color: #fff;
        color: #090909;
        cursor: pointer;
        border: 2px #5370ff solid;
        cursor: pointer;
        display: inline-block;
        // height: 40px;
        height: auto;
        padding:15px 0 ;
        width: 100%;
        border-radius:50px;
        text-align: center;
        margin-bottom: 15px;
        box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.2);
        transition: 0.4s ease-in-out 0s;
        @media screen and (max-width: 768px) {
            margin-bottom: 13px;
            border-radius: 30px;
            
        }

    }
    input[type=checkbox]:checked+label {
        background: url(../../img/tick.png) no-repeat left center;
        background-position: 15px;
        background-size: 20px;
        border: 2px #00ba00 solid;
        cursor: pointer;
        display: inline-block;
        background-color: #b6ffc5;
        color: #068400;
    }
    label {
    // float: left;
    }
    input[type=checkbox] {
        display: none;
    }
}

.skip_btn{
    padding: 20px 5px;
    border: none;
    position: relative;
    z-index: 1;
    background: #bcbcbc;
    color: #000000;
    border-radius: 5px;
    outline-color: none;
}

.option {
    float: left;
    height: auto;
    margin: 0 0 0px 0;
    width: 100%;
    padding: 0 0px;
}
.option_title {
    float: left;
    font-size: 16px;
    font-weight:500;
    height: auto;
    // line-height: 18px;
    margin-left: 45px;
    margin-top: 0;
    position: relative;
    width: 100%;
    text-align: left;
    text-decoration: none;
    top: 0;
    z-index: 120;

    @media (max-width: 768px){
    font-size: 14px;
    }
    img{
        max-height: 30px;
        margin-right: 7px;
        position: absolute;
        right: 60px;
        top: -25px;
    }
}
// selectoptions


}
.morelenderbut{
    cursor: pointer;
}
@media screen and (max-width: 768px) {


}
}