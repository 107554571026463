
.checkmyid {
	font-family:'Poppins', sans-serif !important;  

/*
*******************************************
Template Name: TMS
Author: Abhilash GP
Date:30-07-2024
Template By: Vandalay Designs
Copyright 2024-2025 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

$background_color_1:#ffffff;
$background_color_2:#00b0ef;
$background_color_3:#f0f2f9;


$color_1:#ffffff;
$color_2:#00b0ef;
$color_3: #000;

$font-normal:normal;


// ======border===========



/*================================================
	BODY STYLES
==================================================*/
*, ::after, ::before {
    box-sizing: border-box;
}

*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	color: unset !important;
}

em {
    font-style: normal;
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}


.wrapper_div{
		background: #3a8ddb url(../../img/main-banner.png) no-repeat;
		background-size: cover;
		height: 100vh;
		overflow-x: hidden;
		position: relative;
		h1{
			font-weight: 700;
			color: #fff;
			font-size: calc(1.375rem + 1.5vw);
			margin-bottom: 25px;
		}
		h2{
			font-weight: 700;
			color: #fff;
			font-size:calc(1.015rem + 1.2vw);
		}


		p{
			font-size: 16px;
			line-height: 1.5;
			color: #fff;
			margin-bottom: 7px;
		}
		.content-section{
			height: 80vh;
			display: flex;
			align-items: center;
			padding: 50px 0;
			.button-section{
				margin: 25px 0;
				justify-content: center;
				display: grid;
				.btn-c {
					background: #ef453d;
					padding: 20px 40px;
					border: none;
					position: relative;
					z-index: 1;
					color: #fff !important;
					border-radius: 5px;
					display: block; 
					margin-bottom: 25px;
					max-width: 360px;
					text-align: center;
					span {
						position: relative;
						z-index: 4;
					  }
				  
					  &:after {
						position: absolute;
						content: "";
						width: 0;
						height: 100%;
						top: 0;
						right: 0;
						background-color: #ff912b;
						transition: all 0.3s ease;
						z-index: 3;
						border-radius: 5px;
					  }
				  
					  &:hover {
						color: $color_1;
				  
						&:after {
						  left: 0;
						  width: 100%;
				  
						}
					  }
				  
					  &:active {
						top: 2px;
					  }


				}
			}



		}
}


/* ==================================
    FOOTER
================================== */

footer {
    background: #1b1b1b;
    color: #fff;
    font-size: .8rem;
	p{
			color: #9b9b9b;
	}
	ul {
		padding: 0;
		text-align: left;
		margin-top: 15px;
		li{
			list-style: none;
			border-right: 1px solid #beb8b8;
			padding: 0 10px;
			display: inline;
			color: #beb8b8;
				a {
					padding: 0 15px 0 0;
				}
				&:last-child {
					border: 0;
				}
		}
	}
}

@keyframes pulse-animationhome {
    0% {
      box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  .pulsehome {
    animation: pulse-animationhome 1.5s infinite;
  }
  

  @media screen and (max-width: 768px) {

	header{
		text-align: center;
	}
		.wrapper_div{
			.content-section{
				text-align: center;
				.button-section{

				}
			}
		}


	


  }









}//The Claims Guys 

