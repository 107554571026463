/*
*******************************************
Template Name: CAR REGISTRATION
Author: SHAREEF
Date: 12-06-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$color1: #1F1F1F;
$color2: #bfbfbf;
$color3: #D1D1D1;
$color4: #E23232;
$color5: #E23232;
$color6: #E23232;
$black-color: #000;
$white-color: #fff;

/**/
//////////////////////////Fonts*/////////

$font_family_1: "Poppins", sans-serif !important;
$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

$shdow1 : rgba(0, 0, 0, 0.24) 0px 3px 8px;




.car-regi-middle {
  .text-title {
    font-size: 1.2rem;
  }

  .car-box {
    box-shadow: $shdow1;
    border: 1px solid $color3;
    border-radius: 10px;

    .text1 {
      font-size: .8rem;
    }
  }

  .numb-plate-wrap {
    display: flex;
    gap: 10px;

    .number-plate {
      width: 100%;
      border-radius: 10px;
      background: $white-color;


       

      .input-wrap {
        border: 1px solid $color2 ;

        border-radius: 0 10px 10px 0px;

        input {
          height: 55px;
          border-radius: 0 10px 10px 0px;
          font-size: 1.2rem;
          font-weight: bold;
          border: none;
          box-shadow: none;

          &::placeholder {
            font-weight: 400;
            font-size: 1rem;
          }
        }
      }



    }

    .add-more {
      width: 100px;
      background: $black-color;
      color: $white-color;
      text-align: center;
      font-size: 1rem;
      border-radius: 10px;
      line-height: 33px;
      border: none;
      cursor: pointer;
    }
  }
  
 

  .upload-box {
    cursor: pointer;
  
    @extend .car-box;
  
    .icon-circle {
      background:  #e7e3e3;
      height: 40px;
      width: 40px;
      text-align: center;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      i {
        font-size: 1.4rem;
      }
    }
  
    
      &.updated {
        &.nation-card {
          .icon-circle {
            background: $color4;
            color: $white-color;
          }
        }
        &.license{
          .icon-circle {
            background: $color4;
            color: $white-color;
          }
        }
        &.passport{
          .icon-circle {
            background: $color4;
            color: $white-color;
          }
        }
        
      }
       
  
    h3 {
      font-size: 1.3rem;
    }
  
    .click-upload {
      background: $color1;
      color: $white-color;
      border-radius: 30px;
      text-align: center;
      font-size: 1rem;
      display: inline-block;
    }
  
    .fileupload {
      @extend .click-upload;
      background: #b0f3b7;
      color: #0C9D1B;
    }
  
    .forward-angle {
      margin-left: auto;
      font-size: 1.4rem;
      margin-right: 15px;
    }
  }





}
.fade-scale {
  transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;

  &.show {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
  }

}


.modal-body {

  .title {
    font-size: 1.5rem;
  }

  .com-button {
    border: 1px solid $black-color;
  }
}

.file-uploads-box {
  background: #e5e5e5;
  border-radius: 15px;
  border: 1px dashed $color1;
  position: relative;

  .iput-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }

  i {
    font-size: 4rem;
    color: #a7a7a7;
  }

  .choose-file {
    background: $color1;
    border-radius: 10px;
    color: $white-color;
    font-size: 1.2rem;
  }

  .file-text {
    font-size: 1rem;
  }
}

.file-upload-body {
  .close-btn {
    margin-left: auto;


    &:hover {

      i {
        transform: rotate(180deg);
        transition: ease-in 0.3s;
        transform-origin: center;
      }
    }
  }

  .com-button {
    font-size: 1.1rem;
    min-height: inherit;

    &.takephoto {
      padding: 13px;
    }

    i {
      font-size: 1.2rem;
    }
  }

  .progress {
    width: 100%;
  
    .progress-bar {
      animation: profill 2s ease-in 100ms  both  ;
    }
    @keyframes profill {
      0% {
        width: 0%;
      }
    
      50% {
        width: 100%;
      }
    }
  }

}















