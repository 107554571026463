

.questionnaireUFC_DC_V1{/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$color01: #3A8DDB;
$white: #fff;
$color1: #3A8DDB;
$color2: #1891a5;
$color3: #1B1B1B;
$color4: #1FCCF2;
$color5: #BD3333;
$color6: #1FCCF2;
$color7: #32bfb0;
$color8: #EF8019;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;

$bg-gradient : linear-gradient(to bottom, #399be1, #00a7de, #00b1d2, #00b9c2, #35bfaf);


$font_family_1: 'Poppins', sans-serif !important;



$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;

/*================================================
03 - BODY STYLES
==================================================*/
* {
  outline: none;
}


.progress {
  position: relative;
  height: 20px; // Adjust the height as needed
  width: 100%; // Full width or customize as needed
  background-color: #e9ecef; // Background of the progress container
  border-radius: 10px; // Rounded corners for the container
  overflow: hidden; // Ensures the progress bar doesn't overflow
}

.progress-bar {
  height: 100%;
  background-color:#198754; // Color of the progress bar
  transition: width 0.5s ease-in-out; // Smooth animation for width changes
  border-radius: 10px 0 0 10px; // Keeps left corner rounded
}

.inner-section {
  background: white;
  border-radius: 20px   ;
  padding: 40px;
  margin-bottom: 6rem;
  .text-progress {
    font-size: 16px;
  }
  .hd-lop {
        h1 {
          font-size: 24px;
        }
  }
  .inner {
    background: #E3F7FF;
    padding:40px;
    border: 2px solid #3392B8;
   /*  max-width: 800px;
    margin: 0 auto; */
    .qs-title {
      font-size: 24px;
      line-height: 36px;
      color:  #246782;
    }
  }
}

.btn-option {
 padding: 2rem 0;
 margin: 0;
  li {
    width: 32%;

  }

  &.optinal-items {
    gap: 15px 10px;

    &.two-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap:30px;

      li {
        width: 100%;
      }


    }

    &.one-options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      li {
        width: 100%;
      }
    }

    .option_but {
      label {
        font-size: 15px;
        padding: 15px 10px 15px 20px;
        font-weight: 600;
        font-size: 18px;
        background: $white;
         box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 1px;
        .tickicon {
          position: relative;
          top: 3px;
        }
      }
    }



  }
  &.qs-legthy {
    .option_but {
      label {
        font-size: 16px;
        text-align: start;
      }
    }
  }
  &.optinal-items-payment {
    .option_but {
      label {
        font-size: 16px;
        text-align: start;
        font-weight: 500;
        .tickicon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  &.singleqs {
    .option_but {
      label {
        font-size: 16px;
        justify-content: center;
        padding-left: 10px !important;
        
      }
    }
  }

  


  .option_but {
    
    position: relative;

    input {
      position: absolute;
      display: none;
    }


    label {
      padding: 10px;
      display: block;
      border: 1px solid $color01;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: start;
      
       
      .tickicon {
        width: 25px;
        height: 25px;
        background: $white;
        border: 2px solid   $color01;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;

      }
    }

    input:checked+label {
      background:  #00BD8E;
      color: $white;
      
       .tickicon {
        background:  url(../img/tick03.png) no-repeat center center;
        background-size: 14px;
        border: none;
        border: 2px solid  $white ;
        
      }  
    }

  }

}

.btn-option2 {
   li {
     width: 32%;
 
   }
 
   &.optinal-items {
     gap: 15px 10px;
 
     &.two-options {
       display: grid;
       grid-template-columns: repeat(2, 1fr);
       gap:30px;
 
       li {
         width: 100%;
       }
 
 
     }
 
     &.one-options {
       display: grid;
       grid-template-columns: repeat(1, 1fr);
 
       li {
         width: 100%;
       }
     }
 
     .option_but {
       label {
         font-size: 15px;
         padding: 15px 10px 15px 20px;
         font-weight: 600;
         font-size: 18px;
         background: $white;
          box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 1px;
         .tickicon {
           position: relative;
           top: 3px;
         }
       }
     }
 
 
 
   }
   &.qs-legthy {
     .option_but {
       label {
         font-size: 16px;
         text-align: start;
       }
     }
   }
   &.optinal-items-payment {
     .option_but {
       label {
         font-size: 16px;
         text-align: start;
         font-weight: 500;
         .tickicon {
           width: 22px;
           height: 22px;
         }
       }
     }
   }
   &.singleqs {
     .option_but {
       label {
         font-size: 16px;
         justify-content: center;
         padding-left: 10px !important;
         
       }
     }
   }
 
   
 
 
   .option_but {
     
     position: relative;
 
     input {
       position: absolute;
       display: none;
     }
 
 
     label {
       padding: 10px;
       display: block;
       border: 1px solid $color01;
       border-radius: 5px;
       cursor: pointer;
       text-align: center;
       display: flex;
       gap: 10px;
       align-items: center;
       justify-content: start;
       
        
       .tickicon {
         width: 25px;
         height: 25px;
         background: $white;
         border: 2px solid   $color01;
         border-radius: 50%;
         display: inline-block;
         margin-right: 10px;
 
       }
     }
 
     input:checked+label {
       background:  #00BD8E;
       color: $white;
       
        .tickicon {
         background:  url(../img/tick03.png) no-repeat center center;
         background-size: 14px;
         border: none;
         border: 2px solid  $white ;
         
       }  
     }
 
   }
 
 }

.qsSlider {
  .swiper-slide {
    height: auto !important;
  }
 
}

.back-btn {
 font-size: 20px;
 border: 0;
 background: transparent;

}

.below-text {
  font-size: 14px;
}
 
.sginature {
  .text2-info {
    background:  #CAFFF2;
    color: #36574e;
   }
   .ownedupto {
    font-size: 24px;
    span {
      background: #FFCE2D;
    }
   }
   .text-greatnews{
      font-size: 30px;
      span {
        font-weight: bold;
        font-size: 50px;
      }

      

   }
   .checkbox-set {
    font-size: 18px;
   }
  
}


.signboxsection {
  p {
    font-size: 15px;
  }

  .signbox {
    canvas {
      background: #e4e4e4  url(../img/signhere.png) no-repeat center center  ;
      height: 350px;
      border: 2px dotted $color13;
      border-radius: 10px;
    }

    .sign-clear {
      background: $color5;
      bottom: 20px;
      right: 12px;
      font-size: 14px;

    }
  }
}
 
.checkbox-set {
  font-size: 13px;
 align-items: baseline;
  
  .check-input {
    height: inherit;
    position: relative;
    height: 15px;
    width: 15px;
  }
  label {
    cursor: pointer;
  }
}


.sign-blowtext {
  .sig-title {
    font-weight: 600;
    color: $color01;
    font-size: 15px;
  }
  .link-list {
    display: flex;
    flex-wrap: wrap;
    gap:15px;
     li {
      display: flex;
      gap: 4px;
      color: $color01;
      text-decoration: underline;
     // margin-bottom: 5px;
      //width: 50%;
     }
  }
}
.fullqs-list {
  h1 {
    font-size:30px;
  }
}

.lbl-fnt{
  font-size: 18px;
}

.forms-body  {
    .amt-filed {
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      border-radius: 5px;
      .currency {
        font-size: 24px;
        width: 50px;
        text-align: center;
      }
      .input-wrap {
        width: 80%;
      }
      input {
        box-shadow: initial;
        border: none;
        font-size: 18px;
        &:focus {
          box-shadow: initial;
        }
      }
    }

  .monthlyterm {
    h4 {
      font-size: 14px;
    }
  }
  select {
    border: 1px solid #A3A3A3;
    font-size: 14px;
    height: 55px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    &:focus {
      box-shadow: rgba(247, 176, 44, 0.3) 0px 0px 0px 3px;
    }
  }
}
.odnewscss{
  .tick-check-box{
    padding-left: 35px !important;
  }
  .tick-check-box span {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #1fccf2;
    left: 8px;
  }
}
.odnewscss{
  input:checked+label span {
    background: #306fce url(../img/tick-1.png) center no-repeat;
    background-size: 20px;
    border: 2px solid #306fce;
  }
}


.odflcredlimit input[type="tel"] {
  padding-left: 50px;
  height: 60px;
}
.odflcredlimit {
   position: relative;
}

.odflcredlimit:before {
  content: "£";
  position: absolute;
  top: 10px;
  left: 25px;
  z-index: 1;
  font-size: 25px;
}






    // @media screen and (max-width: 460px) {
    //   .fullqs-list{
    //     h1 {
    //       font-size: 18px !important;
    //     }
    //   }

    //   .stickybtn {
    //     position: sticky;
    //     bottom: 0;
    //   }
    //   .inner-section {
    //     padding: 20px;
    //     .inner {
    //       padding: 20px !important;

    //       .qs-title{
    //         font-size: 17px !important;
    //     line-height: 27px !important;
    //       }
    //     }
    //     .hd-lop {
    //        img {
    //         height: 50px;
    //        }
    //        h1 {
    //         font-size: 16px;
    //        }
    //     }
    //   }
    //   .sub-text {
    //     font-size: 13px;
    //   }

    //   .below-text {
    //     font-size: 11px;
    //     img {
    //       height: 24px;
    //     }
    //   }

    //   .btn-option{
    //     &.optinal-items{
    //       &.two-options {
    //         gap: 15px;
    //       }
    //       .option_but {
    //         label {
    //           padding: 10px 10px 10px 20px;
    //           font-size: 14px;
    //         }
    //       }
    //     }
    //   }


    //   .back-btn {
    //     font-size: 16px;
    //   }  
    //   .sign-blowtext {
    //     .sig-title {
    //       font-size: 13px;
    //     }
    //      .link-list {
    //       li  {
    //         font-size: 12px;
    //       }
    //      }
    //   }

      
    //   .checkbox-set {
    //     align-items: start;
    //     .check-input {
    //       height: 26px;
    //       width: 26px;
    //     }
    //   }
    //   .sginature {
    //     .text-cp {
    //       p {
    //         font-size: 14px;
    //       }
    //     }
    //     .checkbox-set {
    //       font-size: 14px;
    //     }
    //      .ownedupto {
    //       font-size: 16px;
    //     }

    //     p {
    //       font-size: 12px;
    //     }
    //     .text-greatnews {
    //       font-size: 20px;
    //       span {font-size: 28px;}
    //     }
    //   }

    //   .signboxsection {
    //     p {
    //       font-size: 15px;
    //     }
      
    //     .signbox {
    //       canvas {
    //         height: 300px;
    //         border: 0;
    //         border-radius: 0px;
    //       }
      
          
    //     }
    //   }


    // }
    @media screen and (max-width: 460px) {
      .lbl-fnt{
        font-size: 14px;
      }
      .fullqs-list{
        h1 {
          font-size: 18px !important;
        }
      }
      .inner-section {  
        padding: 20px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
        .inner {
          padding: 20px !important;

          .qs-title{
            font-size: 17px;
           line-height: 27px;
          }
        }
        .hd-lop {
          img {
            height: 50px;
          }
          h1 {
            font-size: 16px;
          }
        }
      }
      .sub-text {
        font-size: 13px;
      }

      .below-text {
        font-size: 11px;
        img {
          height: 24px;
        }
      }

      .btn-option{
        &.optinal-items{
          &.two-options {
            gap: 15px;
          }
          .option_but {
            label {
              padding: 10px 10px 10px 10px;
            }
          }
        }
      }


      .back-btn {
        font-size: 16px;
      }

      .forms-body{
        input {
          height: 50px;
        }
      }


    }


.odflpoundSymbol {
  position: relative;
}

.odflpoundSymbol_input[type="tel"] {
  padding-left: 27px;
  min-height: 57px;
}

.odflpoundSymbol:before {
  content: "£";
  position: absolute;
  top: 16px;
  right: -25px;
  z-index: 7;
}

@media screen and (max-width: 768px) {
  .odflpoundSymbol {
    min-height: 65px;
  }

  .odflpoundSymbol:before {
    content: "£";
    position: absolute;
    top: 81px;
    left: 15px;
    z-index: 7;
    width:10px;
  }
  .widsize{
    width: 100%;
 }
}

}