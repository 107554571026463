

/*================================================
01 - Sass Variables
==================================================*/

  // =======background colors========

  $color1: #3a8ddb;
  $color2: #1891a5;
  $color3: #1b1b1b;
  $color4: #1fccf2;
  $color5: #df4514;
  $color6: #1fccf2;
  $color7: #df2c2c;
  $color8: #ef453d;
  $color9: #1ed1bc;
  $color10: #81e05f;
  $color11: #a2d0f9;
  $color12: #946fff;
  $color13: #9b9b9b;

  $black-color: #000;
  $white-color: #fff;

  $font_family_1: "Poppins", sans-serif;

  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $font-w600: 600;
  $font-w700: 700;

  // ======border===========

  $border-style1: solid 1px #fff;
  $border-style2: 1px solid #d3d3d3;
  $border-style3: 5px solid #011a70;

  $border-style4: 3px solid #057599;
  $border-style5: 2px #1483ce solid;
  
  $border-color1: #d3d3d3;
  $border-color2: #dedcdc;
  $border-color3: #000;