// Custom.scss
.OD_UFL_4{
@import "./modules/custom.scss";
@import "./modules/questionnaire.scss";
@import "./modules/iduploads.scss";
@import "./modules/previewODUFL.scss";

// Option A: Include all of Bootstrap

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500&display=swap');
@import url("../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css");
@import url("https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css");
}