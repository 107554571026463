.OD_UFL_4{

    .anim_ylw_preview {
        transition:all ease-in 0.5s ;
        animation:pulse2 1500ms infinite;
      
        @keyframes pulse2{
          0%{
            box-shadow: #ef8019 0 0 0 0;
          }
          75%{
            box-shadow:#ff69b400 0 0 0 16px;
          }
        }
      }
    
      padding-top: 50px;
      background: #eee;
    
    .important-margin {
        margin-top: 0px !important;
      }
    
    .container-1{ 
        padding: 80px 80px 80px 80px;
        width: 64%;
        margin: 120px auto;
        font-family: "Noto Sans TC", sans-serif;
        background-color: white;
        min-height: 1180px;
    }
    
    
    .sec_loa p , .sec_loa li{ 
       font-size: 12px !important;
    }
    
    
    
    .loa_header_address{ 
      font-size: 11px;
      padding-left: 82px;
      font-weight: 400;
    }
    .logo_imgloa{ 
      max-width: 30%;
    }
    .loa_h1{ 
      font-weight: 800;
      font-size: 30px;
      margin-top: 12px;
    }
    
    .loa_table1 td{ 
      border: 1px solid black; 
      border-collapse: collapse;
      padding: 6px; 
      font-size: 12px;
      text-align: center;
    }
    
    .table_1a {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      margin-bottom: 12px;
      width: 100%;
    }
    
    .table_1a td {
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
      padding: 4px;
      font-size: 10px;
    }
    
    
    h5{ 
      font-size: 15px;
      font-weight: 900;
    }
    
    h4{ 
      font-size: 18px;
      color: #000;
      text-align: center;
      font-weight: 700;
      margin-bottom: 20px;
    }
    
    p{
      font-size: 14px;
      font-weight: 600;
    }
    li{
        font-size: 14px;
        padding-right: 10px;
        font-weight: 500;
        margin-top: 18px;
    }
     
      
    
      .next-btn{ 
        position: fixed; 
        bottom: 0px;
        width: 100%;
        text-align: center;
      }
    
      .first_td{ 
        height: 1200px;
        vertical-align: top;
      }
      .table_maxfee, .table_maxfee td{ 
        border: 1px solid black; 
        border-collapse: collapse;
        font-size: 13px;
      }
      .table_maxfee td{ 
        width: 25%;
        padding: 6px;
        text-align: center;
      }
    
      .table_feeeg td{ 
        border: 1px solid black; 
        font-weight: 700;
        border-collapse: collapse;
        font-size: 13px;
        padding: 4px;
      }
    
    
    
    
    
      .dsar-sec{
        table {
          width: 100%;
        }
      
        table tbody {
          width: 100%;
        }
      
        p {
          margin: 0px;
          font-size: 12px;
          padding: 0px;
          line-height: 13px;
          font-weight: 400;
        }
      
        .table_1,
        .table_1 td {
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
          font-size: 13px;
        }
      
        h5 {
          font-size: 12px;
      
        }
      
        ol {
          padding-top: 0;
          padding-bottom: 0
        }
      
        ol li {
          font-size: 10px;
          margin-bottom: 12px
        }
      
      
        .table_1a {
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
          margin-bottom: 12px;
        }
      
        .table_1a td {
          border: 1px solid black;
          border-collapse: collapse;
          text-align: center;
          padding: 4px;
          font-size: 10px;
        }
      }  
      
      .t-table{
        display: flex;
        flex-wrap: wrap;
      }
      
      .table-sec{
        min-height: auto;
        padding-top: 80px;
      }
      
      .t-table_div{
        width: 45%;
        margin: auto;
        border:1px solid #000;
        padding:25px 15px;
        border-radius:30px;
      }
      .logo-tab{
        width:100%; 
        float:right;
      }
      
      .color_one {
        color: #271c6f;
      }
      
      .f-wb {
        font-weight: bold;
      }
      
      .border {
        border: 1px solid #000;
        border-collapse: collapse;
        border-spacing: 0;
      }
      
      .border td {
        border: 1px solid #000;
        padding: 4px
      }
      
      .border th {
        border-right: 1px solid #000
      }
      
      .declare-sec h4{
        text-align: left;
      }
      
      .declare-sec p{
        font-weight: normal;
      }
      
      
    
    
    
      .important-font-size {
        font-size: 11px !important;
        line-height: 14px;
        font-weight: 400;
      }
    
    
    
    
    
    
    
    
    
    
      
    
    
    
    
      /*  **** MEDIA QUERIES **** */
    
    @media only screen and (max-width: 600px) {
     
      .container-1{ 
        padding: 1%;
        width: 94%;
        padding: 24px 16px;
        margin: 36px auto !important;
        min-height: 500px;
    }
    
    .loa_header_address{ 
      padding-left: 25px;
    }
    
    .logo_imgloa {
      max-width: 90%;
    }
    
    .h5{ 
      font-size: 13px;
      font-weight: 900;
    }
    .first_td{ 
      height: auto;
    }
    .ps-5,.px-5{ 
      padding: 0px !important;
    }
    li{ 
      font-size: 12px;
    }
    
    h5{ 
      font-size: 16px;
    }
    
    p{ 
      font-size: 11px;
    }
    
    
    
    }
    
    }


    @media (min-width: 576px) and (max-width: 992px){
      .over_craft {
          max-width: none !important;
          margin-right: auto;
          margin-left: auto;
      }
   }