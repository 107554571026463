.credit_confirm{
    // @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
    background: url(../img/bg-2.png) no-repeat left top !important;
    background-size: cover !important;
    font-family:  'Poppins', sans-serif !important;  
/*
*******************************************
Template Name: Tms Legal
Author: Abhilash GP
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2023-2024 Vandalay Business Solution

* This file contains the styling for the  Tms Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - 
05 - MODAL
06 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/

$background_color_1:#ffffff;
$background_color_2:#3A8DDB;
$background_color_3:#1FCCF2;
$background_color_4:#ff912b;
$background_color_5:#ef453d;


$color_1:#FFFFFF;
$color_2:#4f4f4f;
$color_3: #3A8DDB;
$color_4: #1FCCF2;


$font_size_1:16px;
$font_size_2:14px;




// ======border===========
$border-style1:1px dashed #ffffff;


.show{
    display: block;
  }


  .hide{
    display:none;
  }
  

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

input[type=button] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    display: block;
    cursor: pointer;
}


.error-msg {
    color: #fff;
    background: red;  
    padding-top: 5px;
}


.loader-wrap {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color:#69a2d7 !important;
    cursor: pointer;
}

// body {

// }
.show{
    display: block !important;
}
.hide{
    display: none !important;
}


header{
    padding: 10px 0;
    img{
        height:70px;
    }
}

/*================================================
03 - CONTENT SECTION
==================================================*/
.content{
    padding: 20px 0;
    .intro{
        margin-bottom: 20px;
        img{
            height:150px;
        }
        h2{
            color: $color_1;
            font-weight: 600;
            font-size: 26px;
            margin: 15px 0;
        }
    
        h3{
            color: $color_1;
            font-weight: 600;
            font-size: 20px;
            padding-bottom: 15px;
        }
    
        p{
            color: $color_1;
            font-weight: 400;
            font-size: 14px;
            font-style: italic;
        }

    }
    .doc-op{
        ul{
            margin-bottom: 50px;
            padding: 0px;
            li{
                display: inline;
                font-size: 13px;
                font-style: italic;
                font-weight: 600;
                padding: 0 10px;
                color: $color_1;
                border-right: solid 3px $color_1;
                &:last-child{
                    border:0px;
                }
            }
        }
    }

    .uploading-part{
        .id-box{
            padding: 20px;
            box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
            -webkit-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
            -moz-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
            border-radius: 0 0 20px 20px;
            margin: 0px;
            margin-bottom: 15px;
            background-color: rgba(255, 255, 255, 0.6);
        }
        .upload-area{
            margin:20px 0;
            border-radius: 20px;
            padding: 20px 0 ;
            text-align: center;
            background: $background_color_1;
            box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
            -webkit-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
            -moz-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
        }
    }
   

}
.accordion-body{
    padding: 0px !important;
}

.accordion-item{
    background: none !important;
    border: 0px !important;
}

.accordion-button{
    background-color: $background_color_1 !important;
    margin-bottom: 15px;
    color: $color_3 !important;
    font-weight: bold !important;
    border-radius:10px !important;
    &:focus{
        box-shadow: none !important;
    }
    &::after {
        border: solid 5px #4382bd;
        padding: 15px;
        background-position: center;
        border-radius: 100%;
        background-color: $background_color_1;
    }

}

/*================================================
04 - BUTTON
==================================================*/

.button-tab{
    background: $background_color_5;
    color: $color_1 !important;
    padding: 15px 0;
    border: 0px;
    width: 46%;
    margin: 1%;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    margin-top: 25px;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    cursor: pointer;
    img{
        padding-right: 10px;
    
    }
    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;   
        display: block;
        cursor: pointer;
    }
    &:hover {
        background: $background_color_4 !important;
      }
   
}
.browsebut{
    background: $background_color_5 !important;
    cursor: pointer;
    &:hover{
        background: $background_color_4!important;
    }
}

.id-success{
    width: 46%;
    padding: 0 !important;
    border: none;
    color: #FFFFFF;
    &:hover {
        background: #38cc0b !important;
    }
    button{
        background: #2aa406 !important;
        padding:15px 0;
    }
}
.id-success button {
    width: 100%; 
    height: 100%;
}



    

/*================================================
07 - MODAL
==================================================*/

.modal-content{
    
    .modal-header{

        h4{
            font-size: 28px;
        }
    }

    .modal-body{
        p{
           
        }
        h3{
           
        }
    }
    

    
    table {
        border-collapse: collapse;
    }
    
    tr{
        border-bottom: 1px solid #000;
    }
    
    td{
       border-right: 1px solid #000;
       padding: 5px;
    }
    
    
}


/*================================================
08 - Animation
==================================================*/




/*================================================
09 - MEDIA QUERY
==================================================*/




@media (min-width:1200px) and (max-width : 1440px){
    header{

 
    }
}

$break-ipad-pro: 1024px;

$break-min-ipad: 768px;
$break-max-ipad: 990px;

$break-min-tab: 577px;
$break-max-tab: 767px;

$break-mob: 576px;

@media (min-width:$break-max-ipad)  and (max-width: $break-ipad-pro) {
    header{

   
    }
}

    
@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {
    header{
        
    }
}


@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {
       
}

@media screen and (max-width: $break-mob) {   
   
}

@media screen and (max-width: $break-max-tab) {   
    header{
        img {
            height: 50px;
        }
        }
        .button-tab{
            width: 94%;
            margin: 10px 1%;
            margin-top: 10px;
            font-size: 15px;  
        }

        .content{
            min-height: 100vh;
            height: auto;
            .intro {
                img {
                    height: 115px;
                }
            }
        }

        .id-success{
            background: #2aa406 !important;
            width: 94%;
            font-size: 15px;
        }

    }
   

}