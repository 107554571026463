.claim-outcome{
.bg_gray{
  background: #ececec;

  .goodntoknowshow {
    background: white;
    padding: 1rem;
    border-radius: .5rem;
    margin: 30px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .title {
      .textgood {
        font-size: 1.4rem;
        line-height: 130%;
      }
    }
  
    .title_ques{
      font-size: 1.15rem;
      margin: 0;
    }
  
    p{
      font-size: 14px;
      margin-bottom: 5px;
    }
  
  
    h4 {
      font-size: 1.3rem;
    }
  
    .text-arrow {
      color: #000;
      font-weight: 700;
      margin-bottom: 5px;
    }
  
  }

}
}