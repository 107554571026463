.questionnaire28{
/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$color1: #3A8DDB;
$color2: #1891a5;
$color3: #1B1B1B;
$color4: #1FCCF2;
$color5: #DF4514;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #EF8019;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;



$font_family_1: 'Poppins', sans-serif !important;



$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;

/*================================================
02 - Sass Mixins
==================================================*/

@mixin radius_18 {
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

@mixin shadow_1 {
  -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

@mixin shadow_2 {
  -webkit-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
}

@mixin shadow_in {
  -moz-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

@mixin commButton {
  align-items: center;
  background-clip: padding-box;
  background-color: $color1;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3.8rem;
  padding: calc(1rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:focus {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  }

  &:hover {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-2px);
  }

  &:active {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }

  i {
    margin-left: 10px;
  }
}



/*================================================
03 - BODY STYLES
==================================================*/
.com-button {
  @include commButton;

}

.btn-comm {
  background: $color8;
  padding: 20px 40px;
  border-radius: .4rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: $white-color;
  font-size: 18px;
  position: relative;
  overflow: hidden;

  &.next-btn {
    &::after {
      content: " Next >>";
      background: #f03583;
      transform: translateX(-100%);
      transition: all ease-in 0.3s;
    }
  }



  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: 24px;
    padding-top: 8px;
  }

  span {
    top: 0px;
    transition: all ease-in 0.3s;
  }

  &:hover {
    &:hover {
      color: $white-color;
    }

    &::after {
      transform: translateX(0%);
      transition: all ease-in 0.3s;
    }

    span {
      position: relative;
      top: 30px;
      transition: all ease-in 0.3s;
    }
  }
}

.backbtn {
  // background: transparent;
  border: none;
  height: 30px;
  font-size: 14px;
  padding: 3px 14px 3px 14px;
}


$color_1: #fff;
$background-color_1: #663dff;

/* 15 */
.btn-comm2 {
  background: $color7;
  padding: 20px 40px;
  border: none;
  position: relative;
  z-index: 1;
  color: $white-color;
  border-radius: 5px;
  outline-color: none;

  span {
    position: relative;
    z-index: 4;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #f54242;
    transition: all 0.3s ease;
    z-index: -1;
    border-radius: 5px;
  }

  &:hover {
    color: $color_1;

    &:after {
      left: 0;
      width: 100%;

    }
  }

  &:active {
    top: 2px;
  }
}

.btn-comm3 {
  @extend .btn-comm2;
  background: $color5;

  &:after {
    background: #f35624;
  }
}





html {
  scroll-behavior: smooth;
  font-size: 1rem;
}


body {
  font-family: $font_family_1;
  color: $black-color;


}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;

}

/*================================================
04 - HEADER 
==================================================*/
.sun-strip {
  background: #e2091d;
  color: white;
  font-size: .8rem;
}


.banner {
  background: $color2 url('../../img/main-banner.jpg') no-repeat;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  position: relative;

  .review {
    position: absolute;
    z-index: 99;
    background: $white-color;
    border-radius: 5px;
    right: 0%;
    bottom: -18rem;
    top: auto;
    padding: 10px;

    img {
      border-radius: 10px;
      height: 25px;
    }
  }

  .container {
    margin-bottom: auto;
  }

  .banner-inner {
    height: calc(100vh - 20vh);
    display: flex;
    align-items: center;

    .bannerimage-wraper {
      position: relative;


    }

    .bannerimg {
      position: absolute;
      top: 0px;
      max-height: 500px;
      height: 500px;
      transform: translate(-12px, -102px);

    }

    .banner-caption {
      color: $white-color;

      .legent-car {
        border: 1px solid $color_1;
        border-radius: 10px;

        legend {
          float: none;
          width: auto;
          padding: 0 10px;
          font-size: 1rem;

        }

      }

      .subtext {
        max-width: 80%;
      }

      .payout {
        font-size: 1.5rem;

        .amount {
          position: relative;
          color: yellow;

          svg {
            position: absolute;
            top: -53px;
            left: -8px;
          }
        }
      }



    }

    .btn-comm2 {
      font-size: 1rem;

      &:hover {
        i {
          transform: translate(5px, 0);

          transition: ease-in 0.2s;
        }
      }

      i {
        transform: translate(0px, 0);
        display: inline-block;
        transition: ease-in 0.2s;

      }

    }


    .payout {
      span {
        color: yellow;
      }
    }
  }
}



.welcome-highlights {
  background: $color2;
  color: $white-color;

  h2 {
    font-size: 1.4rem;
    line-height: 27px;
    max-width: 88%;
    margin: 16px auto;
  }

  p {
    font-size: .8rem;
  }

}

.faqsection {
  position: relative;

  &::before {
    content: "";
    background: url(../../img/curve-bg.png) no-repeat;
    width: 100%;
    height: 107px;
    background-position: top center;
    position: absolute;
    top: -103px;
    left: 0;
    background-size: 100%;
  }
}



.sidesection {
  &.is-open {

    //transition: all ease-in 0.4s ;
    opacity: 1;
    transform: translateX(0px);
    transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
  }

  max-width: 650px;
  height: 100%;
  background: $white-color;
  position: fixed;
  right: 0px;
  bottom: 0;
  top: 0;
  z-index: 99;
  opacity: 0;
  transform: translateX(650px);
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
}

.form-body {
  overflow: auto;
  scroll-behavior: smooth;
  height: 600px;
}

.form-style {

  .title {
    font-size: 1.5rem;
  }

  input,
  select {
    border-color: #d1d1d1;
    font-size: .9rem;
    height: 42px;
  }

  select {
    height: 55px;
  }
  .car-number {
    background: white url("../../img/number-plate.png") no-repeat;
    background-size: 40px;
    padding-left: 25px;
    border: 2px solid $black-color;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 2rem ;
    line-height: 0;
    text-align: center;
   height: 60px   ;
    &::placeholder {
      font-size: 1rem;
      font-weight: initial;
      line-height: 0;
      position: relative;
      top: -5px;
    }
  }


  fieldset {
    border: 1px solid #b7b7b7;
    border-radius: 5px;

    legend {
      float: none;
      width: auto;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .sign-sec {
    h5 {
      font-size: 13px;
    }

    .clearbutton {
      background: #eee;
      border: none;
      outline: 0;
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 5px;
    }


    .signbox {

      canvas {
        width: 100%;
        height: 200px;
        border: 1px solid #d5d5d5;
        border-bottom: 2px dotted $black-color;
        background: url(../../img/signhere.png) no-repeat center center;
      }



    }


  }

  .sign-check {
    .checkinput {
      width: 20px;
      height: 20px;
      position: relative;
      top: 6px;
    }

    font-size: 13px;
    text-align: justify;
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      a {
        background: #cdeeff;
        display: block;
        border-radius: 20%;
        padding: 5px 10px;
        border-radius: 35px;
        font-size: 12px;
        text-decoration: underline !important;
      }
    }
  }

}

.card-wrap {
  position: relative;

  input {
    width: 0;
    position: absolute;

    &:checked+label {
      background: $color1;
      color: $white-color;
      border-color: $color1 ;
    }
  }

  .card-item {
    border: 1px solid $color13;
    font-size: .7rem;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    line-height: 16px;
    color: $color13;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    i {
      font-size: 2rem;
    }


  }
}





@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.btn-eff {
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  small {
    &:nth-child(1) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, #e4e4e418, $color10);
      animation: animate1 2s linear infinite;
    }

    // &:nth-child(2) {
    // 	position: absolute;
    // 	top: 0;
    // 	right: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to bottom, #e4e4e418,  $color10);
    // 	animation: animate2 2s linear infinite;
    // 	animation-delay: 1s;
    // }
    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to left, #e4e4e418, $color10);
      animation: animate3 2s linear infinite;
    }

    // &:nth-child(4) {
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to top, #e4e4e418,  $color10);
    // 	animation: animate4 2s linear infinite;
    // 	animation-delay: 1s;
    // }
  }
}



/* animation class and keyframes */
.overflow-hidden {
  overflow: hidden;
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
}

.drop-in-3 {
  animation: drop-in 2000ms ease 100ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}





.connect-account {
  padding-bottom: 30px;

  .btn-comm2 {
    max-width: 350px;
    display: block;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    span {
      font-size: 1.2rem;
    }
  }

  .title {
    font-size: 1.5rem;
  }


}

.bglock {
  background: url("../../img/bg-lock.png") no-repeat center bottom;
}


.btfooter {
  padding-top: 2rem;
  background: #C1E8FF url("../../img/border-img.png") repeat-x top;
  position: relative;

  p {
    font-size: 14px;
    color: #013959;
  }

  &::before {
    content: "";
    width: 58px;
    height: 58px;
    background: url("../../img/secure-img.png");
    display: inline-block;
    position: absolute;
    top: -26px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}





.form-section {
  .form-control {
    height: 50px;
    border-color: $color11;
  }

  .title {
    font-size: 1.2rem;

  }

  fieldset {
    border: 1px solid $color11;
    border-radius: 10px;
    background: $white-color;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .btn-confrm {
    max-width: initial;
  }

  .poscode {
    .btn-postcode {
      background: $color3;
      color: $white-color;
      max-width: 250px;
    }
  }
}

.id-uploads {
  .body-id {
    border: 2px solid #cacaca;
  }

  .btn {
    font-size: 1rem;
  }

  .accordion-item {
    .accordion-button {
      background: #35C6F4;
      border-radius: 5px !important;
      color: $white-color;
      font-weight: 600;
      font-size: 1.2rem;

      &.btn-licence {
        background: $color9;
      }

      &.btnpassport {
        background: $color12;
      }
    }

    .btn-uploads {
      .btn {
        font-size: 1.1rem;

        i {
          font-size: 1.4rem;
        }
      }
    }
  }

  .brows {
    position: relative;

    input[type="file"] {
      position: absolute;
      opacity: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

}


.page-loader {
  padding-top: 10rem;
}

.kycupload {
  .text-start {
    font-size: 1.2rem;
    line-height: 20px;
  }

  .id-uploads {

    .accordion {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

  }

  .btn-comm2 {
    display: inline-block;
  }
}





// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 130px;
$size2: 100px;
$howManySteps: 100; //this needs to be even.

.progress {
  width: $size;
  height: $size !important;
  line-height: $size;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  background: none !important;


  @media only screen and (max-width: 490px) {
    width: $size2;
    height: $size2 !important;
    margin: inherit;
  }

  #progressBar_content {
    line-height: 1rem;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white-color;
  }

  >span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .perce {
    font-size: 1.2rem !important;
    font-weight: bold;
    color: $black-color;
  }

  .progress-left {
    left: 0;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $color3;
  }

  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    z-index: 9;

    span {
      font-size: 12px;
      color: $black-color;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
  $stepName: (
    $i * (100 / $howManySteps)
  );

//animation only the left side if below 50%
@if $i <=($howManySteps/2) {
  .progress[data-percentage="#{$stepName}"] {
    .progress-right .progress-bar {
      animation: loading-#{$i} $animationTime linear forwards;
    }

    .progress-left .progress-bar {
      animation: 0;
    }
  }
}

//animation only the right side if above 50%
@if $i >($howManySteps/2) {
  .progress[data-percentage="#{$stepName}"] {
    .progress-right .progress-bar {
      animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
    }

    .progress-left .progress-bar {
      animation: loading-#{$i -
 ($howManySteps/2)
    }

    $animationTime linear forwards $animationTime;
  }
}
}
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (
    180/ ($howManySteps/2)
  );
$degrees: (
  $degrees * $i
);

@keyframes loading-#{$i} {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate($degrees);
    transform: rotate(#{$degrees}deg);
  }
}
}

// End Progress



.credimodal {
  max-width: 400px !important;
  margin: 0 auto !important;
}













/*================================================
 HEADER END
==================================================*/

/*================================================
04 - BANNER
==================================================*/




.arrows path {
  stroke: #2994D1;
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}


.pulse2 {
  animation: pulse-animation2 1.5s infinite;
}

@keyframes pulse-animation2 {
  0% {
    box-shadow: 0 0 0 0px rgba(238, 255, 7, 0.644);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.forms-fieldset {
  background: rgb(247, 247, 255);

  .headline {
    font-size: 18px;
    font-weight: 700;
  }
}

.list-style {
  li {
    list-style-type: disc !important;
  }
}

.notesec {
  font-size: 13px;
}

ol li {
  list-style-type: decimal;
}

.bg-gray {
  background-color: #F7F7FF;
}

.anchor {
  scroll-margin-top: 100px;
}

.top-head {

  h2,
  .h2 {
    font-size: 2.2rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}



.agree-scroll {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
  padding: 20px;
  background-color: #fff;
}


.stickyBtn {
  position: fixed;
  width: 100%;
  z-index: 238;
  bottom: 0;
  background: #000;
  padding: 20px 0;
}

/*================================================
footer start
==================================================*/
footer {
  background: $color3;
  color: $color_1;
  font-size: .8rem;

  ul {
    li {
      display: inline-block;

      a {
        padding: 0 15px 0 0;
      }
    }
  }

  p,
  a {
    color: $color13;
  }
}



/*================================================
footer end
==================================================*/

.accordion-button {
  &:not(.collapsed) {
    background: $color2 !important;
    color: $white-color !important;
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    }
  }
 
}

.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.quastion-area {
  margin-top: 80px;
  margin-bottom: 80px;

  .accordion-item {
    border: 1px solid #000;

    &:not(:first-of-type) {
      border: 1px solid #000;
    }
  }

  .accordion-button {
    font-size: 20px;
    color: $black-color;
    text-align: center;

    &::after {
      background: url(../../img/down-arrow.png) no-repeat center;
      width: 26px;
      background-size: 15px;
    }

    &:not(.collapsed) {
      color: $white-color;
      background: $color1;

      &::after {
        background: url(../../img/down-arrow.png) no-repeat center;
        width: 26px;
        background-size: 15px;
      }
    }
  }

  .accordion-body {
    p {
      font-size: 18px;
    }
  }
}

.review-section {
  background: #ebf0ff;
  padding: 80px 0 40px 0;

  .swiper-wrapper {
    padding: 10px 30px 30px 0px;
  }

  .feefo-review {
    background: $color1;
    border-radius: 10px;
    padding: 20px;
  }

  .review-box {
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: $color1;
    border-radius: 10px;
    height: 180px;
    transition: all ease-in 0.2s;

    &:hover {
      border-bottom: 5px solid $color1;
      transform: rotate(-1deg);
      transition: all ease-in 0.2s;
    }

    h3 {
      font-size: 16px;
    }

    .starimg {
      height: 25px;
    }
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .loader-box {
    .loader {
      -webkit-animation: rotate-center 0.8s linear infinite both;
      animation: rotate-center 0.8s linear infinite both;
      transform-origin: center;
      width: 80px;
      height: 80px;
    }
  }
}

// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 120px;
$howManySteps: 100; //this needs to be even.



// End Progress

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-vertical-fwd {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-in {
  -webkit-animation: fade-in 2s ease-in both;
  animation: fade-in 2s ease-in both;
}

.shake-vertical {
  -webkit-animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

/*================================================
Keyframe
==================================================*/

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 5s ease-out infinite forwards;
}


@keyframes stroke {

  to {
    stroke-dashoffset: 0;
  }
}


/*================================================
07 - MEDIA QUERY 
==================================================*/
@media screen and (min-width: 1200px) {
  .form-style {
    .select1 {
      padding-right: 1rem;
      font-size: .8rem;
      padding-left: 0.5rem;
    }
  }

}

@media screen and (max-width: 1200px) {
  .welcome-highlights {
    h2 {
      font-size: 1.2rem;
      max-width: initial;
    }
  }

  .faqsection {
    &::before {
      top: -98px;
    }
  }
}

@media screen and (max-width: 991px) {

  .brand {
    text-align: center;

    .logo {
      img {
        height: 52px;
      }
    }
  }

  .banner {
    .review {
      position: absolute;
      bottom: 29px;
      padding: 4px;
      right: 10px;

      img {
        height: 15px;
      }

    }

    .banner-car {
      position: relative;

      .car-img {
        height: 150px;
      }
    }

    .banner-inner {
      .btn-comm2 {
        font-size: 1.4rem;
        padding: 15px 10px;
      }

      .bannerimg {

        height: initial;
        transform: translate(-262px, 13px);
        max-width: 600px;

      }

      .banner-caption {
        h1 {
          font-size: 2rem;
        }


        .subtext {
          max-width: 100%;
        }
      }
    }
  }

  .faqsection {
    &::before {
      top: -72px;
    }
  }

  .form-style {
    .car-number {
      height: 50px;
      font-size: 1.5rem;
      background-size: 33px;

      &::placeholder {
       
        top: -3px;
      }
    }
  }

   

}

@media screen and (max-width: 760px) {

  .banner {

    .banner-inner {
      height: initial;
      margin-top: 2rem;

      .bannerimg {

        max-width: 300px;

      }

      .banner-caption {
        h1 {
          font-size: 2rem;
        }

        .subtext {
          font-size: 13px;
        }
      }

    }
  }


  .faqsection {
    &::before {
      display: none;
    }

    .title-sec {
      h2 {
        font-size: 1.2rem;
      }
    }
  }

  .form-style {
    .sign-sec {
      .signbox {
        canvas {
          max-width: 100%;
        }
      }
    }
  }

  .card-wrap {
    .card-item {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  footer{
    ul {

      li{border-right:0}
    }
  }

}


@media screen and (min-device-width: 470px) and (max-device-width: 768px) {}

@media screen and (max-width: 470px) {
  .sun-strip {
    img {
      height: 25px;
    }
    font-size: .6rem;

  }


  html {
    font-size: .9rem;
  }

 

  .logo-two {
    img {
      height: 50px;
    }
  }

  .banner {
    height: 100vh;
    padding-bottom: 40px;




    .banner-inner {
      .bannerimg {
        transform: translate(-132px, 13px);
      }

      .car-number {
        background-size: 28px;

        &::placeholder {
          font-size: 1.1rem;
        }
      }

      .banner-caption {
        .legent-car {
          legend {
            font-size: .9rem;
          }
        }

        .payout {
          .amount {
            svg {
              height: 131px;
              width: 120px;
              left: -4px;
            }
          }
        }

        .banner-car {
          position: relative;

          .car-img {}
        }
      }


    }


  }

  .ft-logo {
    img {
      height: 55px;
    }
  }

  .form-body {
    height: 98vh;
  }

  .welcome-highlights {
    .items {
      max-width: 75%;
      margin: 0 auto;
    }
  }


}

@media only screen and (max-width: 790px) and (min-width: 540px) {}
}
