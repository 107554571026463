/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/


/*================================================
03 - BODY STYLES
==================================================*/
* {
  outline: none;
}
.full-width-item {
  width: 100% !important;
}
.banner {
  &.qs-section {
    height: initial;
    overflow-x: inherit;
    min-height: 800px;
  }
}

.que-title {
  font-size: 1.1rem;
  margin: 0;
  color: #9b9b9b;
  line-height: 30px;
}

.text-style-1 {
  color: #000 !important;
}

.questionistmod .accordion-button:not(.collapsed) {
  background: #cfe2ff !important;
}

.questionistmod input, select {
  border-color: #d1d1d1;
  font-size: .9rem;
  height: 100%;
  min-height: 45px;
}

.questionnair-section {
  padding-left: 10rem;
  position: relative;


  &::before {
    content: "";
    width: 2px;
    height: 100%;
    background: #ffffff24;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(77px);
  }

  .questitem {
    background: #ffffff3b;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;


    &::before {
      content: "";
      width: 25px;
      height: 25px;
      background: $white-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translateX(-95px);
      transition: all ease-in 0.5s;
      animation: pulse 1500ms infinite;

      @keyframes pulse {
        0% {
          box-shadow: #fa7159 0 0 0 0;
        }

        75% {
          box-shadow: #ff69b400 0 0 0 16px;
        }
      } 


    }

    &.active {
      &::before {
        background: $color7 url("../../img/check.png") no-repeat;
        transition: all ease-in 0.5s;
        background-position: center center;
        animation: initial;
      }
    }

    h1 {
      font-size: 1.1rem;
      margin: 0;
      color: #fff;

      &.text-style {
        color: $white-color;
        // text-decoration: line-through;
      }

      &.text-finish {

        color: #9b9b9b !important;
      }
    }
  }
}

.list {
  li {
    margin-bottom: 10px;

    .optionbtn {

      label {
        padding: 8px;
        display: block;
        font-size: .9rem;
      }


    }
  }
}

.optionbtn {
  .odnewscss{
    .tick-check-box{
      padding-left: 35px !important;
    }
    .tick-check-box span {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 2px solid #1fccf2;
      left: 16px;
    }
  }
  
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
  }

  input:checked+label {
    background: #1891a5;
    color: $white-color !important;
  }
  .odnewscss{
  input:checked+label span {
    background: #306fce url(../../img/tick-1.png) center no-repeat;
    background-size: 20px;
    border: 2px solid #306fce;
  }
}

  label {
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid #fff;
  }
}

.blackover {
  background: rgba(0, 0, 0, .95) !important;
  top: 0% !important;
}


.yespop {
  font-weight: normal !important;
  background: #30bd31 !important;
  &:hover {
    transition: all 0.3s ease;
    background: #298b29 !important;
  }
  &:after {
    background-color: #298b29 !important;
  }
}

.nopop { 
  background: #999ea3 !important;
  &:hover {
    transition: all 0.3s ease;
    background: #6f7172 !important;
  }
  &:after {
    background-color: #6f7172 !important;
  }
}

.popmodalbg {
  background:url("../../img/secure-pop.png") no-repeat center center; background-size: cover; min-height: 200px;
}

.btn-green-skip {
  background-color: rgba(24, 206, 15, .8) !important;
  color: #fff !important;
  width: 100% !important;
  height: 100% !important;
}

.warning-text {
  font-size: 13px;
  font-style: italic;
  color: red;
  text-align: center;
}

.option-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  &.doculist {
    li {
      width: 32.5%;
    }
  }
  .li-lastchld{ 
    width: 34%;
  }
  .li-20{ 
    width: 20%;
  }

  @media screen and (max-width:1200px) {
    .li-lastchld{ 
      width: 100%;
    }
    .li-20{ 
      width: 100%;
    }
  }

  


  li {
    width: 24%;

    .optionbtn {
      label {
        width: 100%;
        color: #1891a5;
        text-align: center;
      }
    }
  }
}

.popupclaim {
  h4 {
    font-size: 1.2rem;
  }
}

.loader-wrap {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    line-height: 1.5rem;
  }

  .succtext {
    animation: showtext 2.5s ease 100ms backwards;
    opacity: 1;
    line-height: 2.3rem;
    animation-delay: 1s;

    small {
      font-size: 1rem;
    }
  }

  @keyframes showtext {
    from {
      opacity: 0;

    }

    to {
      opacity: 1;

    }
  }



}


.drop-down-multi {
  position: relative;
  text-align: left;
  z-index: 999;

  select {
    height: 55px;
  }

  .choices__input {
    background: transparent;
  }

  .choices__inner {
    background: transparent;
    border-radius: 10px;
    border-color: #a2d0f9;
  }

  .choices__list--dropdown {
    z-index: 999 !important;
  }

  .choices__list--multiple {
    .choices__item {
      font-size: 14px;
      background: #ff8989;

    }
  }
}


.section-lender {
  padding-bottom: 8rem;

  .title {
    font-size: 1.8rem;
  }

  .choices__input {
    font-size: 18px;
  }

  .qs-title {
    line-height: 2rem;
    font-size: 1.3rem;
  }
}

.bck-marwid{
  margin-bottom: 4px;
}

.otp-input {
  display: flex;
  justify-content: center;
  gap: 8px;
  @media only screen and (max-width: 768px) {
    gap: 6px;
  }

}
.otp-input input{
  @media only screen and (max-width: 768px) {
    width: 12%!important;
    height: 40px !important;
    min-height: 40px;
  }

  width: 12%!important;
}

.otp-input-field {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none; 
  transition: border-color 0.2s;

}

.otp-input-field:focus {
  border-color: $color5;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.w-sm-100{ 
  width: auto; 
  @media screen and (max-width:768px) {
    width: 100%;
  }
}

.qrange{
  position: fixed;
  z-index: 9;
  top:20px;
  right: 20px;
  width: 100px;
  height:100px;
  text-align:center;
  color:#fff;
  border-radius: 50%;
  vertical-align: middle;
  background-color:rgba(2,55,90,0.8);
  cursor: pointer;
  justify-content: center;
  -webkit-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 13px 40px 0px rgba(0, 0, 0, 0.21);
  transition: all 0.35s cubic-bezier(.91,-0.28,.06,1.3),background-color 0.25s ease;
 
  &:hover{
    background-color:#004c70;
  }
.giveSpace{
  @media (max-width: 579px){
    display: none;
  }
}
  @media screen and (max-width: 579px){
      bottom: 0;
      width: 100%;
      right: 0;
      top: inherit;
      border-radius: 0;
      height: 50px;
      line-height: 50px;

    }

 
}
//////////////////////////////////////////////////////////////////////


.height-scale {
  -webkit-animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-down-ver-center {
  -webkit-animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: .3s;
}

////////////////////////// Keyframe ////////////////////////////////////////////

@-webkit-keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {

    -webkit-transform: scaleY(1);
    transform: scaleY(1);

  }
}

@keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


.questlist-two {
  .monthlyexpences {
    .text-one {
      font-size: .9rem;
    }

    label {
      font-size: .8rem;
    }

    .form-control:focus {
      border-color: #ddd;
      box-shadow: none;
    }
  }

  .option-grid {
    li {
      width: 32.5%;
    }

    &.dependents-list {
      li {
        width: 19%;
      }
    }
  }

}


.goodnews_popup {
  .title {
    font-size: 1rem;
    .textgood {
      font-size: 2rem;
    }
  }
  h4 {
    font-size: 1.3rem;
  }
  p {
    font-size: 11px;
  }
  .btn-comm2 {
    font-size: 1.3rem;
    background: #ef453d;
    &:after {
      background:#e5362e ;
    }
  }
  .text-arrow {
    position: relative;
    display: inline-block;
    .st-arrow {
      position: absolute;
      right: 0;
      bottom: -54px;
    }
  }



  .listfi {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0 25px;
    gap: 0 25px;
    li {
      width: 48%;
      border-bottom: 1px solid #cdcdcd;
      padding: 8px 0;
      display: flex;
      grid-gap: 10px;
      gap: 10px;

      &::before {
        background: url(../../img/download.png);
        background-size: 20px;
        width: 20px;
        height: 20px;
        content: "";
        display: inline-block;
      }
      a {
        color:black;
        font-size: 14px;
        text-decoration: underline !important;
      }
  }
  }
 

}
.signinpopup {
  p {
    font-size: 16px;
  }
	h2 {
		font-size: 26px;
		color: #004c70;
		margin-bottom: 15px;
	}
  .btn-link{
    padding: 5px 10px !important;
  }
	ul {
		list-style-type: none;
		text-align: center;
		margin: 10px 0 20px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		li {
			margin: 8px;
			a {
				list-style: none;
				padding: 0 !important;
				// background-color: #e7e6e6;
				border-radius: 5px;
				font-size: 13px;
			}
		}
	}
	.web-canvas {
		width: 100%;
		border: 1px solid #ced4da;
		border-radius: .25rem;
		margin: auto;
		padding: 10px;
	}
	.popup-sign-sec {
		.signbox {
			canvas {
				border: 1px solid #d5d5d5;
				border-bottom: 2px dotted #000;
				width: 100%;
			}
		}
		.signbutton {
			background-color: #ef453d;
			color: #fff;
			padding: 10px 20px;
			border: 0;
			border-radius: 7px;
			min-width: 110px;
			font-size: 16px;
		}
	}
	.btn {
		font-size: 16px;
	}
}





////////////////////////// Keyframe ////////////////////////////////////////////

@media screen and (max-width: 990px) {

  .questionnair-section {
    .questitem {
      h1 {
        font-size: 1rem;
      }
    }



    .option-grid {

      li {
        width: 100%;

      }

      &.doculist {
        li {
          width: 100%;
        }
      }

      &.dependents-list {
        li {
          width: 100%;
        }
      }

    }



  }

  .list {
    li {
      .optionbtn {
        label {
          padding: 10px;
        }
      }
    }
  }

  .section-lender {
    .title {
      font-size: 1.2rem;
      line-height: 1.6rem;

      img {
        height: 110px;
        margin-right: 10px;
      }
    }

    .qs-title {
      line-height: initial;
      font-size: 1rem;
    }

    .choices__input {
      font-size: 14px;
    }

  }
  .goodnews_popup {
    .listfi {
      li {
        width: 100%;
      }
    }
  }

}


@media screen and (min-width: 960px) {
 
}

@media screen and (max-width: 470px) {


  .questionnair-section {
    padding-left: 2rem;

    &::before {
      transform: translateX(8px);
    }

    .questitem {
      h1 {
        font-size: 1rem;
      }

      &::before {
        transform: translateX(-29px);
        width: 18px;
        height: 18px;

      }

      &.active {
        &::before {
          background-size: 12px;
        }
      }
    }

    .section-lender {
      .title {
        font-size: 1.2rem;

        img {
          height: 86px;
          margin-right: 10px;
        }
      }

      .qs-title {
        line-height: initial;
        font-size: 1.1rem;
      }

    }
  }

  .signinpopup  {
    ul{
      li {
        a {
          font-size: 10px;
        }
      }
    }
    
  }

}
.bck-marwid{
  margin-bottom: 4px;
}

.odflcredlimit input[type="tel"] {
  padding-left: 20px;
}

.odflcredlimit:before {
  content: "£";
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 1;
}

.odflpoundSymbol {
  position: relative;
}

.odflpoundSymbol_input[type="tel"] {
  padding-left: 27px;
}

.odflpoundSymbol:before {
  content: "£";
  position: absolute;
  top: 15px;
  right: -25px;
  z-index: 7;
}

@media screen and (max-width: 768px) {
  .odflpoundSymbol {
    min-height: 65px;
  }

  .odflpoundSymbol:before {
    content: "£";
    position: absolute;
    top: 80px;
    left: 15px;
    z-index: 7;
    width:10px;
  }
  
}
