@import "personal-details.scss";
@import "edit-details.scss";
@import "car-registration.scss";


/*
*******************************************
Template Name: Lookup Finance
Author: Abhilash
Date: 28-09-2024
Template By: Vandalay Designs
Copyright 2024-2025 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========
.edit-detailsVAQ_CCV1{
$color1: #2145dd;
$color2: #f3d766;
$color3: #111111;

$black-color: #000;
$white-color: #fff;

 

$font_family_1: 'Poppins', sans-serif !important;  



/*================================================
02 - Sass Mixins
==================================================*/
@mixin btn-style1 {
  background: #3780ad;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 15px;
  max-width: 275px;
  display:block;
  text-align: center;
  -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  margin: 10px auto 20px;
}



/*================================================
03 - BODY STYLES
==================================================*/


$color_1: #fff;
$background-color_1: #2145dd;

html {
  scroll-behavior: smooth;
  font-size: 1rem;
}

.disabled-link {
 display: none;
}


body {
  font-family: $font_family_1;
  color: $black-color;

    
}
ul,
li {
  list-style-type: none;
}
a {
  text-decoration: none !important;
 
}
/*================================================
04 - HEADER 
==================================================*/

@mixin clearfix() {
  &:before,
  &:after {
      content: "";
      display: table;
  }
  &:after {
      clear: both;
  }
} 


header{
  background: #ffffff; 
  -webkit-box-shadow: 1px 2px 12px -1px rgba(0,0,0,0.23);
  -moz-box-shadow: 1px 2px 12px -1px rgba(0,0,0,0.23);
  box-shadow: 1px 2px 12px -1px rgba(0,0,0,0.23);
  position: relative;
  z-index: 999;
  padding: 10px 0;
}

.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video scales and covers the full area */
  z-index: -1; /* Places the video behind content */
}
.banner {
  background: #ffffff;
  min-height: 85vh;
  overflow-x: hidden;
  position: relative;
  padding-top:0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    font-weight: 900;
    font-size: 35px;
    margin-bottom: 20px;
  }
h2{
  font-weight: bold;
  font-size: 28px;
  &::after{
    width: 40%;
    content: ' ';
    height: 5px;
    top: 2px;
    background-color: #f3d766;
    position: relative;
    display: block;
    border-radius: 5px;
    -webkit-animation: bounce-x 3s infinite linear;
    animation: bounce-x 3s infinite linear;
  }
}
h3{
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
}
p{
  strong{
    color: #2145dd;
    font-weight: bolder;
  }
}

@keyframes bounce-x {
  0% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }

  50% {
      -webkit-transform: translateX(15px);
      transform: translateX(15px)
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0)
  }
}

.nowin{
  background-color: #F3D766;
  color: #594906;
  font-size: 20px;
  border-radius: 30px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  min-width: 280px;
  padding: 15px 10px 15px 62px;
  margin-bottom: 25px;
  position: relative;
  span{
    background-color: #2145dd;
    border-radius: 100px;
    position: absolute;
    left: 0;
    padding: 15px;
    top: 0;
    width: 62px;
  }
}

}




/// STICKY SCROLL ANIMATION DESIGN


.news-ticker {
  background: #dddddd;
  color: #2f2f2f;
  overflow: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
      height: 35px;
  }
}

.ticker-content {
  display: flex;
}

.logos {
  display: none;
  margin-right: 20px;
}

.logos.active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logos .GuardianLogo {
  max-width: 130px;
  background: #d4d4d4;
  position: relative;
  z-index: 4;
  padding: 10px;
  @media (max-width: 768px) {
      max-width: 100px;
  }
}
.logos .bbcLogo {
  max-width: 134px;
  background: #d4d4d4;
  position: relative;
  z-index: 4;
  padding: 10px;
  @media (max-width: 768px) {
      max-width: 100px;
  }
}
.logos .telegraphLogo {
  max-width: 169px;
  background:  #d4d4d4;
  position: relative;
  z-index: 4;
  padding: 10px;
  @media (max-width: 768px) {
      max-width: 100px;
  }
}

.contenty {
  white-space: nowrap; /* Ensures content stays in a single line */
  overflow: hidden;    /* Hides any overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis (...) if content is too long */
  animation: ticker-animation 10s linear infinite;
}

@keyframes ticker-animation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


.pos_stick {
  z-index: 4;
  transition: transform 0.5s ease-in-out;
  top: 0;
  position: absolute;
  width: 100%;
}

.smooth_bu {
  position: relative;
  transform: translateY(0);
  transition: transform 0.5s ease-in-out; /* Updated transition */
}
// 


.stepsec{
  background-color:#2145dd;
  padding: 30px 15px;
  color: #ffffff;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
 
  h2{
    font-size: 20px;
  }
  img{
    max-height:90px;
    transition: transform .7s ease-in-out;
  }

    &:hover {
      img{
        transform: rotate(360deg);
      }
  
    }
}

.you-claim{
  background-color: #DBE2FF;
  padding:60px 15px;
  color: #0E0E0E;
  position: relative;
  &:hover{
    .youcliam-icons{
      transform: rotate(360deg);
    }
  }
  
  h3{
    font-size:28px;
    font-weight: 600;
    margin-bottom: 15px;

  }
  h4{
    font-size:22px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  h5{
    font-size:17px;
    font-weight: 600;
    margin-bottom: 15px;
  }


  p{
    font-size: 14px;
    margin: 0 0 15px;
  }

  .youcliam-icons{
    height: 80px;
    width: 80px;
    margin: 15px auto;
    background-color:#2145dd ;
    padding: 10px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: transform .7s ease-in-out;
  
    img{
      transition: transform .7s ease-in-out;
      &:hover {
        transform: rotate(360deg);
      }
    }

  
   
  }





  .blue-color{
    color: #2145dd !important;
  }
  .bounce-y {
    -webkit-animation: bounce-y 10s infinite linear;
    animation: bounce-y 10s infinite linear;
}
.experience {
  position: absolute;
  left: 50px;
  bottom: 100px;
  min-width: 220px;
  background: #2145dd;
  padding: 15px 30px 25px;
  border-radius: 10px 10px 0 10px;
  -webkit-box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
  color: #fff;
  &::before {
    position: absolute;
    right: -20px;
    bottom: 0;
    border-left: 20px solid #2145dd;
    border-top: 20px solid transparent;
    content: ""
}
}

@keyframes bounce-y {
  0% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }

  50% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px)
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0)
  }
}

}

.faqsection {
  position: relative;
  h2{
    color: #2145DD;
    font-weight: 600;
  }
}

// BUTTON

.btn-web{
@include btn-style1;
  &:hover{
    background:#fbda53;
    transform: translate(0px, -10px) scale(1.1);
    -webkit-transform: translate(0px, -10px) scale(1.1);
    -moz-transform: translate(0px, -10px) scale(1.1);
    -ms-transform: translate(0px, -10px) scale(1.1);
    -o-transform: translate(0px, -10px) scale(1.1);
    transition: all ease-in-out 300ms;
  }
}


.btn-banner{
@include btn-style1;
  margin: 10px 0 20px;
  border:0px;
  min-width: 300px;
  font-size: 22px;
  display: inline-block;
  &:hover{
    background:#00bd8e;
  }
  span{
    margin-left: 20px;
  }
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite
}

@keyframes zoom-in-zoom-out {
  0% {
      transform: scale(0.8, 0.8)
  }

  50% {
      transform: scale(1, 1)
  }

  100% {
      transform: scale(0.8, 0.8)
  }
}


.fadein-eff {
  opacity: 0;
  transition: all 1.5s;
  transform: translate(0, 100px)
}
.fadein-eff.visible {
  opacity: 1;
  transform: translate(0, 0)
}

@media screen and (max-width: 579px) {

}


@media screen and (max-width: 991px) {

}


/* animation class and keyframes */
.overflow-hidden {
  overflow: hidden;
 }
 
 .drop-in {
  animation: drop-in 1s ease 200ms backwards;
 }
 
 .drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
 }
 .drop-in-3 {
  animation: drop-in 2000ms ease 100ms backwards;
 }
 
 @keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(100px);
  }
  to {
   opacity: 1;
   transform: translateY(0px);
  }
 }


/*================================================
 HEADER END
==================================================*/

/*================================================
04 - BANNER
==================================================*/
 

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0.5);
  }
  50% {
    box-shadow: 0 0 0 20px rgba(41, 148, 209, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0);
  }
}

/*================================================
footer start
==================================================*/
footer {
  background:  $color3;
  color: #9b9b9b;
  font-size:.8rem;
  ul {
    li {
      display: inline-block;
      a {
        padding:0 15px 0 0;
        color:#ffffff;
      }
      &:hover{
        text-decoration: underline;
      }
    }
    .bord{
      border-left: 1px solid #fff;
      padding: 0 10px;
      border-right: 1px solid #fff;
    }
  }
}
 

/*================================================
footer end
==================================================*/





/*================================================
07 - MEDIA QUERY 
==================================================*/
@media screen and (min-width: 1200px) {

  
}
@media screen and (max-width: 1200px) {

}

@media screen and (max-width: 991px) {
  


}

@media screen and (max-width: 768px) {
  header{
    .header-logo{
      height: 50px;
      margin-top: 5px;
    }
    .header-logo-2{
      height: 15px;
    }
    .header-logo-3{
      height: 35px;
    }
  }
  .banner {
    min-height: 75vh;
    text-align: center;
    padding-bottom: 0px;
    h2{
      font-size: 16px;
      &::after{
        height: 4px;
        width:54%;
        left:20%;
      }
    }
    .nowin{
      font-size: 16px;
      padding: 10px 10px 10px 62px;
      span {
        padding: 7px;
        width: 45px;
        text-align: center;
    }
    }
  }
.banner-subpage{
  min-height: 95vh;
}

  .stepsec{
    img {
      max-height: 45px;
  }
  h2{
    font-size: 14px;
    padding: 0 10px;
  }
  }
  .you-claim{
    text-align: center;
    h3{
      font-size: 22px;
    }
    h4{
      font-size:17px;
    }
  }
  .faqsection {
    &::before{
      background-size: cover;
    }
    .title-sec {
      h2 {
        font-size: 1.2rem;
      }
     }
   }

  
}

 
@media screen and (min-device-width: 470px) and (max-device-width: 768px) { 
  
}



@media only screen and (max-width: 790px) and (min-width: 540px) {
  
}

@media only screen and (max-width: 540px) and (min-width: 470px) {
 
  
}

}
