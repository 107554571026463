.UFC_DC_V1{
.fade-in {
	-webkit-animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.anim_ylw {
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  box-shadow: 0 0 0 0 #359f14;

  @-webkit-keyframes pulse {
          to {
          box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
          }
  }

  @-moz-keyframes pulse {
      to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
      }
  }
  
  @-ms-keyframes pulse {
      to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
      }
  }
  
  @keyframes pulse {
      to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
      }
  }
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.slide-up-now{
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 


 
 @-webkit-keyframes slide-top {
  
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
  }
}
@keyframes slide-top {
  
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
            opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
  }
}
}












